import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import _ from 'lodash';
import { compose } from 'recompose';
import ScheduleForm from '~/components/ScheduleForm';
import { connect } from 'dva';

const FormItem = Form.Item;
const { Option } = Select;
const contentOptions = [
  'disease_index',
  'fitness_index',
  'movement_quality',
  'nutrition_index',
  'stress_index',
];

export class QuestionnaireScore extends Component {
  static propTypes = {
    onSelectQuestionnaireScore: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
    scoreTypes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    scoreType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    scoreTypes: [],
    scoreType: '',
  }

  handleSelect = (id, option) => {
    const { onSelectQuestionnaireScore } = this.props;
    const payload = {
      score_type: id,
    };

    return onSelectQuestionnaireScore(id, option, payload)();
  }

  render() {
    const {
      form,
      form: { getFieldDecorator },
      scoreTypes,
      scoreType,
      trigger,
    } = this.props;

    return (
      <div>
        <FormItem label="Score Type">
          {getFieldDecorator('score_type', {
            initialValue: _.includes(scoreTypes, scoreType) ? scoreType : [],
            rules: [
              {
                required: true,
                message: 'Please select a score type',
              },
            ],
          })(
            <Select onSelect={this.handleSelect}>
              {scoreTypes.map(option => <Option key={option} value={option}>{_.startCase(option)}</Option>)}
            </Select>,
          )}
        </FormItem>
        <ScheduleForm form={form} trigger={trigger} />
      </div>
    );
  }
}

export default compose(
  connect(() => ({ scoreTypes: contentOptions })),
  Form.create(),
)(QuestionnaireScore);
