import React, { PureComponent } from 'react';
import { Form, Input, Button, Card } from 'antd';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;
const { TextArea } = Input;

export default class CommunicationForm extends PureComponent {
  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      console.log('communication id:', this.props.match.params.id);
      this.props.dispatch({
        type: 'communications/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { communication, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          console.log(values);
          this.props.dispatch({
            type: 'communications/update',
            payload: {
              id: communication.id,
              params: values,
            },
          });
        } else if (type === 'new') {
          console.log(values);
          this.props.dispatch({
            type: 'communications/create',
            payload: {
              params: values,
            },
          });
        }
      }
    });
  };
  render() {
    const { communication, loading, submitting, type } = this.props;
    console.log('loading', loading);
    console.log('type', type);
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
      },
    };

    return (
      <PageHeaderLayout
        title={communication ? `Edit Communication: ${communication.subject}` : 'Create Communication'}
        logo={
          <img alt="" src="https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" />
        }
      >
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="Subject">
              {getFieldDecorator('subject', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a subject',
                  },
                ],
              })(<Input placeholder="eg. Bob" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Message Content">
              {getFieldDecorator('message_content', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the message_content',
                  },
                ],
              })(<TextArea className={styles.textAreaMinHeight} placeholder="Description" rows={4} />)}
            </FormItem>
            <FormItem {...submitFormLayout} className={styles.formItemMarginTop}>
              <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderLayout>
    );
  }
}
