import React, { Component, Fragment } from 'react';
import { Modal } from 'antd';
/*

  withModal(Klass)

  class Klass extends Component {
    handleOpen = () => {
      const title = getTitle();
      const body = getBody();

      this.props.openDrawer(title, body);
    }
  }
*/
const withModal = WrappedComponent => class extends Component {
    state = {
      isModalOpen: false,
      title: undefined,
      body: undefined,
      props: {},
    }

    handleOpenModal = (title, body, props) => {
      this.setState({
        isModalOpen: true,
        title,
        body,
        props: { ...props },
      });
    }

    handleCloseModal = () => {
      this.setState({ isModalOpen: false });
    }

    render() {
      const {
        isModalOpen,
        title,
        body,
        props,
      } = this.state;

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            openModal={this.handleOpenModal}
            closeModal={this.handleCloseModal}
          />
          <Modal
            visible={isModalOpen}
            width={800}
            title={title}
            footer={null}
            onCancel={this.handleCloseModal}
            {...props}
            destroyOnClose
          >
            {body}
          </Modal>
        </Fragment>
      );
    }
};

export default withModal;
