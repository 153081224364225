import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { Form, Radio } from 'antd';
import _ from 'lodash';
import { compose } from 'recompose';
import AutocompleteSearch from '~/components/AutocompleteSearch';
import ScheduleForm from '~/components/ScheduleForm';
import { deserialise } from '~/utils/jsonApi/deserialise';

const FormItem = Form.Item;

export class QuestionnaireAdd extends Component {
  static propTypes = {
    searchQuery: PropTypes.string,
    onSelectQuestionnaire: PropTypes.func.isRequired,
    questionnairesList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
    loadingQuestionnaires: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    loadingQuestionnaires: false,
    searchQuery: '',
  }

  state = {
    key: 'my_library',
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'questionnaires/fetch',
      payload: {
        pageNumber: 1,
      },
    });
  }

  handleSearch = (searchQuery) => {
    const { key } = this.state;
    this.props.dispatch({
      type: 'questionnaires/fetch',
      payload: {
        pageNumber: 1,
        searchQuery,
        extraQueries: {
          [key]: true,
        },
      },
    });
  }

  handleSelect = (id, option) => {
    const { onSelectQuestionnaire, questionnairesList } = this.props;
    const item = _.find(questionnairesList, ['id', id]);
    let questionnaireItems;

    try {
      questionnaireItems = item.relationships.questionnaire_items.data;
    } catch (error) {
      questionnaireItems = [];
    }

    const payload = deserialise({
      data: { ...item },
      included: [...questionnaireItems],
    });

    return onSelectQuestionnaire(id, option, payload.data);
  }

  handleChangeRadio = (e) => {
    const { searchQuery } = this.props;
    this.setState({
      key: e.target.value,
    }, () => {
      this.handleSearch(searchQuery);
    });
  };

  render() {
    const {
      questionnairesList,
      loadingQuestionnaires,
      form,
      trigger,
    } = this.props;

    return (
      <div>
        <FormItem label="Questionnaire Search">
          <Radio.Group onChange={this.handleChangeRadio} value={this.state.key} style={{ margin: '5px 0px 5px 0px' }}>
            <Radio value="my_library">Organization</Radio>
            <Radio value="by_default_account">Account</Radio>
          </Radio.Group>
          <AutocompleteSearch
            resourceType="Questionnaire"
            onSearch={this.handleSearch}
            onSelect={this.handleSelect}
            resourcesList={questionnairesList}
            loading={loadingQuestionnaires}
            titleKey="name"
            descriptionKey="description"
          />
        </FormItem>
        <ScheduleForm form={form} trigger={trigger} />
      </div>
    );
  }
}

export default compose(
  connect(({ questionnaires, loading }) => ({
    searchQuery: questionnaires.searchQuery,
    questionnairesList: questionnaires.list,
    loadingQuestionnaires: loading.effects['questionnaires/fetch'],
  })),
  Form.create(),
)(QuestionnaireAdd);
