import { compose, mapProps } from 'recompose';
import { connect } from 'dva';
import { Form } from 'antd';
import OrganizationForm from './OrganizationForm';

export default compose(
  connect(({ errors }) => ({
    formErrors: errors.formErrors,
  })),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'new',
  })),
)(OrganizationForm);
