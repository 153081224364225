import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import DatePickerWithTooltip from '~/components/DatePickerWithTooltip';
import moment from 'moment';
import FormError from '~/components/FormError';
import ScheduleForm from '~/components/ScheduleForm';
import ActivityQuantityFields from './ActivityQuantityFields';
import ActivityTagFields from './ActivityTagFields';

const FormItem = Form.Item;
const { Option } = Select;

class ActivityForm extends PureComponent {
  static propTypes = {
    selectedActivity: PropTypes.object.isRequired,
    onUpdateActivity: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
  }

  static defaultProps = {
    submitting: false,
  }

  state = {
    addedTags: [],
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedActivity: { id }, onUpdateActivity } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('ActivityForm values', values);
        onUpdateActivity(id, values);
      }
    });
  };

  render() {
    const { form, form: { getFieldDecorator }, submitting } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormError />
        <FormItem label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Please enter a description',
              },
            ],
          })(<Input placeholder="description of the care plan activity" />)}
        </FormItem>

        <ActivityQuantityFields form={form} />

        <FormItem label="Daily Amount">
          {getFieldDecorator('daily_amount', {
            rules: [
              {
                required: true,
                message: 'Please enter daily amount',
              },
            ],
          })(<InputNumber min={0} />)}
        </FormItem>

        <FormItem label="Quantity">
          {getFieldDecorator('quantity', {
            rules: [
              {
                required: true,
                message: 'Please enter the quantity',
              },
            ],
          })(<InputNumber min={0} />)}
        </FormItem>

        <ActivityTagFields form={form} />

        <ScheduleForm form={form} />

        <FormItem label="Scheduled">
          {getFieldDecorator('scheduled_at', {
            rules: [
              {
                required: true,
                message: 'Please enter daily amount',
              },
            ],
          })(
            <DatePickerWithTooltip
              placeholder="10/28/1974"
              format="MM/DD/YYYY"
            />,
          )}
        </FormItem>

        <FormItem label="Progress">
          {getFieldDecorator('progress', {
            rules: [
              {
                required: true,
                message: 'Please enter notes about progress',
              },
            ],
          })(<Input placeholder="progress of the care plan activity" />)}
        </FormItem>

        <FormItem label="Status">
          {getFieldDecorator('status', {
            rules: [{ required: true, message: 'Please select your status' }],
          })(
            <Select
              placeholder="Select a Option and change input text above"
            >
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>,
          )}
        </FormItem>

        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      description: Form.createFormField({
        value: props.selectedActivity.description,
      }),
      daily_amount: Form.createFormField({
        value: props.selectedActivity.daily_amount,
      }),
      quantity: Form.createFormField({
        value: props.selectedActivity.quantity,
      }),
      scheduled_at: Form.createFormField({
        value: moment(props.selectedActivity.scheduled_at),
      }),
      progress: Form.createFormField({
        value: props.selectedActivity.progress,
      }),
      status: Form.createFormField({
        value: props.selectedActivity.status,
      }),
      // TODO: wire up
      freq_type: Form.createFormField({
        value: 'reps',
      }),
      freq_reps: Form.createFormField({
        value: 1,
      }),
      freq_sets: Form.createFormField({
        value: 1,
      }),
      freq_time: Form.createFormField({
        value: 1,
      }),
      freq_unit: Form.createFormField({
        value: 'minutes',
      }),
      freq_repeats: Form.createFormField({
        value: 1,
      }),
      region_tags: Form.createFormField({
        value: ['Lumbar', 'Thoracic'],
      }),
      custom_tags: Form.createFormField({
        value: [],
      }),
      type_tags: Form.createFormField({
        value: ['Stabilization'],
      }),
      equipment_tags: Form.createFormField({
        value: ['Chair'],
      }),
      start_date: Form.createFormField({
        value: 'assigned',
      }),
      start_date_number: Form.createFormField({
        value: 1,
      }),
      start_date_unit: Form.createFormField({
        value: 'days',
      }),
      end_date: Form.createFormField({
        value: 'discharged',
      }),
      end_date_number: Form.createFormField({
        value: 1,
      }),
      end_date_unit: Form.createFormField({
        value: 'days',
      }),
      schedule_freq: Form.createFormField({
        value: 'every',
      }),
      schedule_freq_number: Form.createFormField({
        value: 1,
      }),
      schedule_freq_unit: Form.createFormField({
        value: 'days',
      }),
      schedule_freq_days: Form.createFormField({
        value: ['monday'],
      }),
    };
  },
})(ActivityForm);
