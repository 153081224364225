import { connect } from 'dva';
import { Form } from 'antd';
import { compose } from 'recompose';
import FormFilter from './form_filter';

export default compose(
  connect(({ workflows, loading, errors }, { triggerId }) => ({
    workflow: workflows.resource,
    workflowOptions: workflows.options,
    trigger: workflows.normalized.triggered_actions[triggerId].attributes,
    loading: loading.effects['workflows/read'],
    submitting: loading.effects['workflows/updateTrigger'],
    formErrors: errors.formErrors,
  })),
  Form.create(),
)(FormFilter);
