import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row } from 'antd';
import _ from 'lodash';
import Description from './Description';
import styles from './index.module.less';

class DescriptionList extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    col: PropTypes.number,
    layout: PropTypes.oneOf(['horizontal', 'vertical']),
    gutter: PropTypes.number,
    size: PropTypes.oneOf(['large', 'small']),
    dataSource: PropTypes.object,
    pickedKeys: PropTypes.arrayOf(PropTypes.string),
    omittedKeys: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
  }

  static defaultProps = {
    className: '',
    col: 3,
    layout: 'horizontal',
    gutter: 32,
    size: 'small',
    dataSource: {},
    pickedKeys: [],
    omittedKeys: [],
    children: null,
    title: '',
  }

  renderList() {
    const { dataSource, pickedKeys, omittedKeys, col } = this.props;
    const column = col > 4 ? 4 : col;
    if (!_.isEmpty(pickedKeys)) {
      const pickedValues = _.pick(dataSource, pickedKeys);
      return _.map(pickedValues, ((value, key) => (
        <Description term={_.startCase(key)} column={column}>{typeof value === 'boolean' ? value.toString() : value}</Description>
      )));
    } else if (!_.isEmpty(omittedKeys)) {
      const omittedValues = _.omit(dataSource, omittedKeys);
      return _.map(omittedValues, ((value, key) => (
        <Description term={_.startCase(key)} column={column}>{typeof value === 'boolean' ? value.toString() : value}</Description>
      )));
    }
    return _.map(dataSource, ((value, key) => (
      <Description term={_.startCase(key)} column={column}>{typeof value === 'boolean' ? value.toString() : value}</Description>
    )));
  }

  render() {
    const {
      className,
      title,
      col = 3,
      layout = 'horizontal',
      gutter = 32,
      children,
      size,
      dataSource,
      ...restProps
    } = this.props;
    const clsString = classNames(styles.descriptionList, styles[layout], className, {
      [styles.small]: size === 'small',
      [styles.large]: size === 'large',
    });
    const column = col > 4 ? 4 : col;
    return (
      <div className={clsString} {...restProps}>
        {title ? <div className={styles.title}>{title}</div> : null}
        <Row gutter={gutter}>
          {React.Children.map(children, child => (
            child ? React.cloneElement(child, { column }) : child
          ))}
          {this.renderList()}
        </Row>
      </div>
    );
  }
}

export default DescriptionList;
