import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import OrganizationContactForm from './OrganizationContactForm';

export default compose(
  connect(({ errors, organizations }) => ({
    formErrors: errors.formErrors,
    organizationContact: organizations.resource.contact,
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create({
    mapPropsToFields({ organizationContact }) {
      return (organizationContact && {
        purpose: Form.createFormField({
          value: organizationContact.purpose,
        }),
        website: Form.createFormField({
          value: organizationContact.website,
        }),
        email: Form.createFormField({
          value: organizationContact.email,
        }),
        phone: Form.createFormField({
          value: organizationContact.phone,
        }),
        city: Form.createFormField({
          value: organizationContact.city,
        }),
        state: Form.createFormField({
          value: organizationContact.state,
        }),
        zip_code: Form.createFormField({
          value: organizationContact.zip_code,
        }),
      });
    },
  }),
)(OrganizationContactForm);
