import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'antd';
import { renderAttribute } from '~/utils/utils';
import styles from './index.module.less';
import responsive from './responsive';

const Description = ({ term, column, className, forceRender = false, children, ...restProps }) => {
  const clsString = classNames(styles.description, className);
  return (
    <Col className={clsString} {...responsive[column]} {...restProps}>
      {term && <div className={styles.term}>{term}</div>}
      {children !== null && children !== undefined &&
        <div className={styles.detail}>
          {forceRender ? children : renderAttribute(children, term)}
        </div>}
    </Col>
  );
};

Description.defaultProps = {
  term: '',
};

Description.propTypes = {
  term: PropTypes.node,
};

export default Description;
