import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Spin } from 'antd';
import withResource, { resourceProps } from '~/hocs/withResource';
import { compose } from 'recompose';
import PatientEdit from './PatientEdit';
import PatientCreate from './PatientCreate';

export class PatientDrawer extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdatePatient: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
    modalType: PropTypes.string.isRequired,
    onCreatePatient: PropTypes.func.isRequired,
    personType: PropTypes.oneOf(['Patient', 'Practitioner']).isRequired,
    patients: resourceProps,
  }

  static defaultProps = {
    loading: false,
    selectedPatient: null,
    patients: {},
  }

  getTitle = () => {
    const { modalType, personType } = this.props;
    const person = personType === 'Patient' ? 'Participant' : personType;
    return modalType === 'edit' ? `Edit ${person}` : `Create ${person}`;
  }

  renderForm = () => {
    const {
      loading,
      onCancel,
      modalType,
      patients,
      selectedPatient,
      personType,
      onUpdatePatient,
      onCreatePatient,
    } = this.props;
    let form = <noscript />;
    if (modalType === 'edit') {
      form = (
        <PatientEdit
          selectedPatient={selectedPatient}
          onUpdatePatient={onUpdatePatient}
          submitting={patients.submitting}
          onCancel={onCancel}
          personType={personType}
        />
      );
    } else if (modalType === 'create') {
      form = (
        <PatientCreate
          onCreatePatient={onCreatePatient}
          submitting={patients.submitting}
          onCancel={onCancel}
          personType={personType}
        />
      );
    }
    if (loading) {
      return (
        <Spin>
          {form}
        </Spin>
      );
    }
    return form;
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {this.renderForm()}
      </Drawer>
    );
  }
}

export default compose(
  withResource('patient'),
)(PatientDrawer);
