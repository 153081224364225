import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ContentPreview from './ContentPreview';
import EmailTemplatePreview from './EmailTemplatePreview';
import TemplatePreview from './TemplatePreview';
import QuestionnairePreview from './QuestionnairePreview';

class ParamPreview extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    small: PropTypes.bool,
  }

  static defaultProps = {
    small: false,
  }

  render() {
    const { params, small } = this.props;
    if (!_.isEmpty(params)) {
      const isContent = params.plannable_id && params.plannable_type && params.plannable_type === 'Content';
      if (isContent) {
        return (
          <ContentPreview
            key={params.plannable_id}
            small={small}
            content={params}
          />
        );
      }

      const isEmailTemplate = !!params.email_template_id;
      if (isEmailTemplate) {
        return (
          <EmailTemplatePreview
            key={params.email_template_id}
            emailTemplate={params}
            email
          />
        );
      }

      const isTemplate = !!params.template_id;
      if (isTemplate) {
        return (
          <TemplatePreview
            key={params.template_id}
            template={params}
          />
        );
      }

      const isQuestionnaire = !!params.questionnaire_id;
      if (isQuestionnaire) {
        return (
          <QuestionnairePreview
            key={params.questionnaire_id}
            questionnaire={params}
          />
        );
      }

      const isQuestionnaireScore = !!params.score_type;
      if (isQuestionnaireScore) {
        const scoreType = _.startCase(params.score_type);
        return (
          <div>
            {scoreType}
          </div>
        );
      }
    }
    return (
      <div>
        {JSON.stringify(params)}
      </div>
    );
  }
}

export default ParamPreview;
