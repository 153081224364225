import React, { PureComponent } from 'react';
import { Form, Input, Button, Card, Switch } from 'antd';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;

export default class ResourceForm extends PureComponent {
  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      console.log('media asset id:', this.props.match.params.id);
      this.props.dispatch({
        type: 'media_assets/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { media_asset, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          console.log(values);
          this.props.dispatch({
            type: 'media_assets/update',
            payload: {
              id: media_asset.id,
              params: values,
            },
          });
        } else if (type === 'new') {
          console.log(values);
          this.props.dispatch({
            type: 'media_assets/create',
            payload: {
              params: values,
            },
          });
        }
      }
    });
  };

  render() {
    const { media_asset, loading, submitting, type } = this.props;
    console.log('loading', loading);
    console.log('type', type);
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
      },
    };

    return (
      <PageHeaderLayout
        title={media_asset ? `Edit Media Asset: ${media_asset.name}` : 'Create Media Asset'}
        logo={
          <img alt="" src="https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" />
        }
      >
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a name',
                  },
                ],
              })(<Input placeholder="eg. media asset" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Language">
              {getFieldDecorator('language', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a language',
                  },
                ],
              })(<Input placeholder="language" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Identifier">
              {getFieldDecorator('identifier', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter an identifier',
                  },
                ],
              })(<Input placeholder="identifier" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator('active',
                {
                  valuePropName: 'checked',
                })(<Switch />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Content ID">
              {getFieldDecorator('content_id', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter an ontent_id',
                  },
                ],
              })(<Input placeholder="content_id" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Media ID">
              {getFieldDecorator('media_id', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a media_id',
                  },
                ],
              })(<Input placeholder="media_id" />)}
            </FormItem>

            <FormItem {...submitFormLayout} className={styles.formItemMarginTop}>
              <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderLayout>
    );
  }
}
