import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select } from 'antd';
import FormError from '~/components/FormError';
import styles from '~/components/ResourceList/ResourceList.module.less';
import { getItemValue } from '~/utils/utils';

const FormItem = Form.Item;
const { Option } = Select;
const statusList = ['draft', 'active', 'suspended', 'completed', 'entered-in-error', 'cancelled', 'unknown'];

class TemplateForm extends PureComponent {
  static propTypes = {
    onCreateTemplate: PropTypes.func.isRequired,
    onUpdateTemplate: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    formErrors: PropTypes.array.isRequired,
    template: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intent: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onCreateTemplate, onUpdateTemplate, form, type } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'new') {
          onCreateTemplate(values);
        } else if (type === 'edit') {
          onUpdateTemplate(values);
        }
      }
    });
  };

  render() {
    const { form: { getFieldDecorator }, submitting, template } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        <FormItem label="Title">
          {getFieldDecorator('title', {
            initialValue: getItemValue(template, 'title'),
            rules: [
              {
                required: true,
                message: 'Please enter title',
              },
            ],
          })(<Input placeholder="eg. Template Onboarding Template" />)}
        </FormItem>

        <FormItem label="Intent">
          {getFieldDecorator('intent', {
            initialValue: getItemValue(template, 'intent'),
            rules: [
              {
                required: true,
                message: 'Please enter intent',
              },
            ],
          })(<Input placeholder="eg. To onboard new patients" />)}
        </FormItem>

        <FormItem label="Description">
          {getFieldDecorator('description', {
            initialValue: getItemValue(template, 'description'),
          })(<Input placeholder="eg. First time patient" />)}
        </FormItem>

        <FormItem label="Status">
          {getFieldDecorator('status', {
            initialValue: getItemValue(template, 'status'),
            rules: [
              {
                required: true,
                message: 'Please select status',
              },
            ],
          })(
            <Select
              placeholder="Select status"
            >
              {statusList.map(status => <Option key={status}>{status}</Option>)}
            </Select>,
          )}
        </FormItem>

        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default TemplateForm;
