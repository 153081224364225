import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import _ from 'lodash';
import { Form } from 'antd';
import { compose } from 'recompose';
import ScheduleForm from '~/components/ScheduleForm';
import AutocompleteSearch from '~/components/AutocompleteSearch';

const FormItem = Form.Item;

class TemplateAdd extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onSelectTemplate: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    templatesList: PropTypes.array.isRequired,
    loadingTemplates: PropTypes.bool,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    loadingTemplates: false,
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'templates/fetch',
      payload: {
        pageNumber: 1,
      },
    });
  }

  handleSearch = (searchQuery) => {
    this.props.dispatch({
      type: 'templates/fetch',
      payload: {
        pageNumber: 1,
        searchQuery,
      },
    });
  }

  handleSelect = (id, option) => {
    const { onSelectTemplate, templatesList } = this.props;
    const item = _.find(templatesList, ['id', id]);
    return onSelectTemplate(id, option, item);
  }

  render() {
    const { form, templatesList, loadingTemplates, trigger } = this.props;
    return (
      <div>
        <FormItem label="Template Search">
          <AutocompleteSearch
            resourceType="Template"
            onSearch={this.handleSearch}
            onSelect={this.handleSelect}
            resourcesList={templatesList}
            loading={loadingTemplates}
            titleKey="title"
            descriptionKey="description"
          />
        </FormItem>
        <ScheduleForm form={form} trigger={trigger} />
      </div>
    );
  }
}

export default compose(
  connect(({ templates, loading }) => ({
    templatesList: templates.list,
    loadingTemplates: loading.effects['templates/fetch'],
  })),
  Form.create(),
)(TemplateAdd);
