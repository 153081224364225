import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Switch, Select, Button, Card, Spin } from 'antd';
import _ from 'lodash';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import ContentEditor from '~/components/ContentEditor';
import EditableTagGroup from '~/components/EditableTagGroup';
import FormError from '~/components/FormError';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 10 },
  },
};

export class ResourceForm extends PureComponent {
  static propTypes = {
    contentResource: PropTypes.object,
    loading: PropTypes.bool,
    submitting: PropTypes.bool,
    type: PropTypes.oneOf(['new', 'edit']).isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    contentType: PropTypes.oneOf(['Education', 'Exercise']).isRequired,
    history: PropTypes.object.isRequired,
    contents: PropTypes.object,
  }

  static defaultProps = {
    contentResource: null,
    loading: false,
    submitting: false,
    contents: {
      resource: {
        read_only: false,
      },
    },
  }

  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      this.props.dispatch({
        type: 'contents/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  getTitle = () => {
    const { contentResource, contentType, type, loading } = this.props;
    if (type === 'new') {
      return `Create ${_.startCase(contentType)}`;
    } else if (type === 'edit' && contentResource && !loading) {
      return `Edit ${_.startCase(contentType)}: ${contentResource && contentResource.title}`;
    }
    return '';
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { contentResource, type, contentType, contents: { resource: { read_only } } } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {
          // Education
          is_template,
          is_helpful,
          body,
          save_to_clinic,
          tags,
          // Exercise
          number_of_reps,
          number_of_sets,
          exercise_type,
          repeats,
          time,
          time_type,
          video_url,
          photo_url,
          ...otherValues
        } = values;

        const params = {
          properties: {
            // Education
            is_template,
            is_helpful,
            save_to_clinic,
            // Exercise
            number_of_reps,
            number_of_sets,
            exercise_type,
            repeats,
            time,
            time_type,
            video_url,
            photo_url,
          },
          ...otherValues,
          body,
          contentType,
        };

        if (type === 'edit') {
          if (!read_only) {
            this.props.dispatch({
              type: 'contents/update',
              payload: {
                id: contentResource.id,
                params,
              },
            });
          }
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'contents/create',
            payload: {
              params,
              tagParams: {
                tag_name: tags,
              },
            },
          });
        }
      }
    });
  };

  handleAddTag = (inputValue) => {
    const { match: { params: { id } }, type, contents: { resource: { read_only } } } = this.props;
    if (id && type === 'edit' && !read_only) {
      this.props.dispatch({
        type: 'resource_tags/create',
        payload: {
          id,
          resourceType: 'contents',
          params: {
            tag_name: inputValue,
          },
        },
      });
    }
  }

  handleRemoveTag = (tagName) => {
    const { match: { params: { id } }, type, contents: { resource: { read_only } } } = this.props;
    if (id && type === 'edit' && !read_only) {
      this.props.dispatch({
        type: 'resource_tags/delete',
        payload: {
          id,
          resourceType: 'contents',
          params: {
            tag_name: tagName,
          },
        },
      });
    }
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleSetFieldValue = field => (value) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue({ [field]: value });
  };

  renderSecondTabFormItems = () => {
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const { contentType, contents: { resource: { read_only } } } = this.props;

    if (contentType === 'Exercise') {
      return (
        <div>
          <FormItem {...formItemLayout} label="Number of Reps">
            {getFieldDecorator('number_of_reps', {
              rules: [
                {
                  required: true,
                  message: 'Please enter number_of_reps',
                },
              ],
            })(<Input type="number" placeholder="eg. number_of_reps" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Number of Sets">
            {getFieldDecorator('number_of_sets', {
              rules: [
                {
                  required: false,
                  message: 'Please enter number_of_sets',
                },
              ],
            })(<Input type="number" placeholder="eg. number_of_sets" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Exercise Type">
            {getFieldDecorator('exercise_type', {
              rules: [
                {
                  required: false,
                  message: 'Please enter exercise_type',
                },
              ],
            })(<Input placeholder="eg. exercise_type" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Repeats">
            {getFieldDecorator('repeats', {
              rules: [
                {
                  required: false,
                  message: 'Please enter repeats',
                },
              ],
            })(<Input type="number" placeholder="eg. repeats" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Time">
            {getFieldDecorator('time', {
              rules: [
                {
                  required: false,
                  message: 'Please enter time',
                },
              ],
            })(<Input type="number" placeholder="eg. time" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Time Type">
            {getFieldDecorator('time_type', {
              rules: [
                {
                  required: false,
                  message: 'Please enter time_type',
                },
              ],
            })(<Input placeholder="eg. time_type" />)}
          </FormItem>
        </div>
      );
    } else if (contentType === 'Education') {
      return (
        <div>
          <FormItem>
            {getFieldDecorator('body_raw', {
              rules: [
                {
                  required: false,
                  message: 'Please enter content',
                },
              ],
            })(<ContentEditor disabled={read_only} setFieldsValue={setFieldsValue} />)}
          </FormItem>
          {getFieldDecorator('body')(<noscript />)}
        </div>
      );
    }
    return <noscript />;
  }

  render() {
    const { submitting, loading, contents: { resource: { read_only } } } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <PageHeaderLayout>
        <Card
          bordered={false}
          title={this.getTitle()}
        >
          <Spin spinning={loading}>
            <Form onSubmit={this.handleSubmit}>
              <FormError />
              <FormItem {...formItemLayout} label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a title',
                    },
                  ],
                })(<Input disabled={read_only} placeholder="Title" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Active">
                {getFieldDecorator('active',
                  {
                    valuePropName: 'checked',
                  })(<Switch disabled={read_only} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Tags">
                {getFieldDecorator('tags', { initialValue: [] })(
                  <EditableTagGroup
                    handleAddTag={this.handleAddTag}
                    handleRemoveTag={this.handleRemoveTag}
                    onSetValue={this.handleSetFieldValue('tags')}
                    disabled={read_only}
                  />,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Description">
                {getFieldDecorator('description')(
                  <Input placeholder="Description" disabled={read_only} />,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Status">
                {getFieldDecorator('status', {
                  rules: [
                    { message: 'Please select your status' },
                    {
                      required: true,
                      message: 'Please enter a status',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select an option"
                    disabled={read_only}
                  >
                    <Option value="draft">Draft</Option>
                    <Option value="published">Published</Option>
                  </Select>,
                )}
              </FormItem>
              {this.renderSecondTabFormItems()}
              <FormItem className={styles.formItemRight}>
                <Button onClick={this.handleSubmit} type="primary" disabled={read_only} loading={submitting}>
                  Save
                </Button>
                <Button className={styles.buttonStyles} onClick={this.handleCancel}>
                  Cancel
                </Button>
              </FormItem>
            </Form>
          </Spin>
        </Card>
      </PageHeaderLayout>
    );
  }
}

export default ResourceForm;
