import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { connect } from 'dva';

import QuestionnaireRenderer from '~/components/QuestionnaireRenderer';
import { getSelectedActivity } from '~/utils/selectors';
import RichTextPreview from './RichTextPreview';
import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import styles from './ActivityPreview.module.less';

class ActivityPreview extends PureComponent {
  static propTypes = {
    activityType: PropTypes.oneOf(['care_plans', 'templates']).isRequired,
    selectedActivityId: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    selectedActivity: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedActivity: {
      plannable: {
        title: '',
      },
      plannable_type: '',
    },
  }

  renderMedia = (media) => {
    switch (media.type) {
      case 'video':
        return <VideoPreview url={media.url} />;
      case 'image':
        return <ImagePreview url={media.url} />;
      default:
        return <noscript />;
    }
  }

  renderBody = () => {
    const { selectedActivity } = this.props;
    switch (selectedActivity.plannable_type) {
      case 'Content':
        return (
          <div className={styles.previewContainer}>
            {selectedActivity.media_content.length > 0 && this.renderMedia(selectedActivity.media_content[0])}
            <RichTextPreview content={selectedActivity.plannable.body} />
          </div>
        );
      case 'Questionnaire':
        return <QuestionnaireRenderer readOnly items={selectedActivity.plannable.fhir.item} />;
      default:
        return <noscript />;
    }
  }

  render() {
    const { selectedActivity, visible, onCancel } = this.props;
    return (
      <Modal
        title={`Preview - ${selectedActivity.plannable.title || selectedActivity.plannable.name}`}
        visible={visible}
        onCancel={onCancel}
        width={800}
        footer={null}
        destroyOnClose
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect((state, props) => ({
  ...(props.activityType && props.selectedActivityId) && {
    selectedActivity: getSelectedActivity(state, props.activityType, props.selectedActivityId),
  },
}))(ActivityPreview);
