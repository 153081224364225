import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getActivityImage } from '~/utils/media';
import styles from './Plannable.module.less';

class PlannableComponent extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    showScore: PropTypes.bool,
  }

  static defaultProps = {
    showScore: true,
  }

  renderScore = (item) => {
    if (item.plannable_type === 'Questionnaire' && item.status === 'completed') {
      return (
        <div>Score: {item.scores ? item.scores : 0}</div>
      );
    }
  }

  render() {
    const { item, showScore } = this.props;

    return (
      <div className={styles.taskType}>
        <img src={getActivityImage(item)} alt={item.plannable.title} />
        <div className={styles.description}>
          <h4>{item.plannable.title}</h4>
          <span>{item.plannable.content_type}</span>
          <span>{item.plannable.fhir.resourceType}</span>
          {showScore && this.renderScore(item)}
        </div>
      </div>
    );
  }
}

export default PlannableComponent;
