import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { findAndReplace } from '~/utils/utils';
import styles from './styles.less';

export const HorizontalTable = (props) => {
  const { scroll, columns, disabled } = props;

  const columnModifications = [
    {
      title: 'Action',
      properties: {
        width: 80,
        fixed: 'right',
      },
    },
    {
      title: 'Status',
      properties: {
        width: 110,
      },
    },
    {
      title: 'Added On',
      properties: {
        width: 110,
      },
    },
  ];
  const modifiedColumns = columnModifications.reduce((cols, col) => {
    const { title, properties } = col;
    return findAndReplace(
      cols,
      ({ title: t }) => t === title,
      obj => ({ ...obj, ...properties }),
    );
  }, [...columns]);

  return (
    <Table
      {...props}
      columns={modifiedColumns}
      scroll={{ x: scroll }}
      rowClassName={disabled ? styles.disabled : ''}
    />
  );
};

HorizontalTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOf(PropTypes.node, PropTypes.string),
    key: PropTypes.string.isRequired,
    dataIndex: PropTypes.string,
    sorter: PropTypes.bool,
    render: PropTypes.func,
  })).isRequired,
  scroll: PropTypes.bool,
  disabled: PropTypes.bool,
};

HorizontalTable.defaultProps = {
  scroll: true,
  disabled: false,
};

export default HorizontalTable;
