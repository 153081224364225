import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import FormTrigger from './form_trigger';

export default compose(
  connect(({ workflows, loading, errors }, { triggerId }) => {
    const { triggered_actions } = workflows.resource;

    const selectedTrigger = triggered_actions.find(({ id }) => id === triggerId);

    return {
      workflow: workflows.resource,
      workflowOptions: workflows.options,
      trigger: selectedTrigger,
      loading: loading.effects['workflows/read'],
      submitting: loading.effects['workflows/updateTrigger'],
      formErrors: errors.formErrors,
    };
  }),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
)(FormTrigger);
