import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import { getActionOptions } from '~/utils/workflows';
import FormAction from './form_action';

export default compose(
  connect(({ workflows, loading, errors }, { triggerId }) => {
    const trigger = workflows.normalized.triggered_actions[triggerId].attributes;
    return {
      workflow: workflows.resource,
      actionOptions: getActionOptions(workflows.options, trigger.domain, trigger.event),
      submitting: loading.effects['workflows/createAction'],
      formErrors: errors.formErrors,
    };
  }),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'new',
  })),
)(FormAction);
