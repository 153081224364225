import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import QuestionnaireItemForm from './form';

export default compose(
  connect(({ questionnaires, loading, errors }) => ({
    questionnaire: questionnaires.resource,
    submitting: loading.effects['questionnaire_items/create'],
    formErrors: errors.formErrors,
  })),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'new',
  })),
)(QuestionnaireItemForm);
