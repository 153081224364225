import React, { PureComponent } from 'react';
import { Cascader, Form, Button } from 'antd';
import FormError from '~/components/FormError';
import { getCascaderOptions } from '~/utils/workflows';

const FormItem = Form.Item;

export default class FormTrigger extends PureComponent {
  componentDidMount() {
    const { trigger, form: { setFieldsValue } } = this.props;
    if (trigger) {
      setFieldsValue({
        trigger: [
          trigger.domain,
          trigger.event,
        ],
      });
    }
  }

  handleSubmitTriggerAction = (e) => {
    e.preventDefault();
    const { workflow, type, trigger } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          this.props.dispatch({
            type: 'workflows/updateTrigger',
            payload: {
              trigger: {
                id: trigger.id,
                workflow_id: workflow.id,
                name: values.name,
                domain: values.trigger[0],
                event: values.trigger[1],
                filters_tree: trigger.filters_tree,
                filters: trigger.filters,
              },
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'workflows/createTrigger',
            payload: {
              trigger: {
                workflow_id: workflow.id,
                name: values.name,
                domain: values.trigger[0],
                event: values.trigger[1],
                filters_tree: '["~#\'",null]',
                filters: {},
                active: false,
              },
            },
          });
        }
      }
    });
  };

  render() {
    const { workflowOptions, submitting, form: { getFieldDecorator } } = this.props;

    return (
      <Form onSubmit={this.handleSubmitTriggerAction} layout="vertical">
        <FormError />
        <FormItem label="Trigger">
          {getFieldDecorator('trigger', {
            rules: [
              {
                required: true,
                message: 'Please select a trigger',
              },
            ],
          })(<Cascader options={getCascaderOptions(workflowOptions)} />)}
        </FormItem>
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmitTriggerAction} type="primary" loading={submitting}>
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
