import React, { PureComponent } from 'react';
import { Form, Input, Button, Card } from 'antd';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import styles from '~/components/ResourceList/ResourceList.module.less';
import MaskedInput from '~/components/MaskedInput';

const FormItem = Form.Item;

export default class UserForm extends PureComponent {
  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      console.log('user id:', this.props.match.params.id);
      this.props.dispatch({
        type: 'users/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { user, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          console.log(values);
          this.props.dispatch({
            type: 'users/update',
            payload: {
              id: user.id,
              params: values,
            },
          });
        } else if (type === 'new') {
          console.log(values);
          this.props.dispatch({
            type: 'users/create',
            payload: {
              params: values,
            },
          });
        }
      }
    });
  };
  render() {
    const { user, loading, submitting, type } = this.props;
    console.log('loading', loading);
    console.log('type', type);
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
      },
    };

    return (
      <PageHeaderLayout
        title={user ? `Edit User: ${user.username}` : 'Create User'}
        logo={
          <img alt="" src="https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" />
        }
      >
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="First Name">
              {getFieldDecorator('first_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a first name',
                  },
                ],
              })(<Input placeholder="eg. Bob" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Last Name">
              {getFieldDecorator('last_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a last name',
                  },
                ],
              })(<Input placeholder="eg. Dobbs" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Please enter a Email',
                  },
                ],
              })(<Input className={styles.maxWidth} placeholder="bdobbs@gmail.com" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Phone Number">
              {getFieldDecorator('phone_number', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a phone number',
                  },
                ],
              })(<MaskedInput className={styles.maxWidth} placeholder="123-456-7890" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a Password',
                  },
                ],
              })(<Input type="password" className={styles.maxWidth} placeholder="bdobbs@gmail.com" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Password Confirmation">
              {getFieldDecorator('password_confirmation', {
                rules: [
                  {
                    required: true,
                    message: 'Confirm password',
                  },
                ],
              })(<Input type="password" className={styles.maxWidth} placeholder="bdobbs@gmail.com" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Organization ID">
              {getFieldDecorator('organization_id', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter an Organization ID.',
                  },
                ],
              })(<Input className={styles.maxWidth} placeholder="bdobbs@gmail.com" />)}
            </FormItem>
            <FormItem {...submitFormLayout} className={styles.formItemMarginTop}>
              <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderLayout>
    );
  }
}
