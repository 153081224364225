import _ from 'lodash';

/**
 * Get options for dropdown by filtering on array of objects
 * @param {array} options - Array of objects
 * @param {string} filterKey - Key to filter on
 * @param {string || int} filterValue - Value to filter
 * @param {string} valueKey - Key for options value
 */
function getOptionsByKeyValue(options, filterKey, filterValue, valueKey) {
  const matchingOptions = _.filter(options, { [filterKey]: filterValue });
  const uniqueValues = _.uniq(matchingOptions.map(item => item[valueKey]));
  return uniqueValues.map(value => ({
    value,
    label: value,
  }));
}

// have array of objects
// find unique domains
// find unique events per domain
// return commands per domain
// domains: { children: events: { children: commands } }

export function getCascaderOptions(workflowOptions) {
  const uniqueDomains = _.uniq(workflowOptions.map(item => item.domain));
  return uniqueDomains.map(domain => ({
    value: domain,
    label: domain,
    children: getOptionsByKeyValue(workflowOptions, 'domain', domain, 'event'),
  }));
}

export function getSelectedOption(workflowOptions, domain, event) {
  return _.find(workflowOptions, {
    domain,
    event,
  });
}

export function getActionOptions(workflowOptions, domain, event) {
  const matchingOptions = _.filter(workflowOptions, { domain, event });
  return _.uniq(matchingOptions.map(item => item.command));
}

export function getReadableCommand(command) {
  const readableCommands = {
    'Notification::Send': 'Send Notification',
    'Content::Add': 'Add Content to Care Plan',
    'CarePlan::ApplyTemplate': 'Apply Care Plan Template',
    'Questionnaire::Send': 'Add Questionnaire to Care Plan',
    'QuestionnaireScore::Create': 'Score Questionnaire',
  };
  return readableCommands[command];
}
