import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './ImagePreview.module.less';

class ImagePreview extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
  }
  render() {
    const { url } = this.props;
    return (
      <img
        className={styles.imagePreview}
        src={url}
        alt="media"
      />
    );
  }
}

export default ImagePreview;
