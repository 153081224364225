import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { getFhirValue } from '~/utils/questionnaire';

const CheckboxGroup = Checkbox.Group;

class MultiChoice extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { options, ...otherProps } = this.props;
    return (
      <CheckboxGroup
        options={options.map(option => ({
          label: getFhirValue(option),
          value: getFhirValue(option),
        }))}
        {...otherProps}
      />
    );
  }
}

export default MultiChoice;
