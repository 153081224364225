import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { connect } from 'dva';
import styles from './FormError.module.less';

class FormError extends PureComponent {
  static propTypes = {
    formErrors: PropTypes.arrayOf(PropTypes.shape({
      detail: PropTypes.string,
      id: PropTypes.string,
      source: PropTypes.object,
      title: PropTypes.string.isRequired,
    })).isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { formErrors, dispatch } = this.props;
    if (formErrors.length > 0) {
      dispatch({
        type: 'errors/clearErrors',
      });
    }
  }

  componentWillUnmount() {
    const { formErrors, dispatch } = this.props;
    if (formErrors.length > 0) {
      dispatch({
        type: 'errors/clearErrors',
      });
    }
  }

  renderMessages = () => {
    const { formErrors } = this.props;
    return (
      <ul>
        {formErrors.map(error => <li key={error.id}>{error.title}</li>)}
      </ul>
    );
  }

  render() {
    const { formErrors } = this.props;
    if (formErrors.length > 0) {
      return (
        <div className={styles.alertContainer}>
          <Alert
            className={styles.alert}
            message="Error"
            description={this.renderMessages()}
            type="error"
            showIcon
          />
        </div>
      );
    }
    return <noscript />;
  }
}

export default connect(({ errors }) => ({
  formErrors: errors.formErrors,
}))(FormError);
