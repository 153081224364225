/* eslint-disable react/sort-comp, default-case */
import { Value } from 'slate';
import CannerEditor from 'canner-slate-editor';
import { DEFAULT_RULES } from '@canner/slate-editor-html';
import { commonMark, commonNode, imageNode, linkNode, emojiNode, videoNode } from '@keethealth/slate-editor-renderer';
import HtmlSerializer from 'slate-html-serializer';
import { AlignCenter, AlignLeft, AlignRight } from '@canner/slate-icon-align';
import Blockquote from '@canner/slate-icon-blockquote';
import Table from '@canner/slate-icon-table';
import { Header1, Header2, Header3 } from '@canner/slate-icon-header';
import Hr from '@canner/slate-icon-hr';
import { Indent, Outdent } from '@canner/slate-icon-indent';
import Link from '@canner/slate-icon-link';
import { OlList, UlList } from '@canner/slate-icon-list';
import Undo from '@canner/slate-icon-undo';
import Redo from '@canner/slate-icon-redo';
import Video from '@keethealth/slate-icon-video';
import Image from '@keethealth/slate-icon-image';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, InputNumber, Popover } from 'antd';
import initialValue from './initialValue.json';
import styles from './ContentEditor.module.less';

const serializer = new HtmlSerializer({ rules: DEFAULT_RULES });

/**
 * The rich text example.
 *
 * @type {Component}
 */

class ContentEditor extends Component {
  static propTypes = {
    value: PropTypes.string,
    setFieldsValue: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: '',
  }

  /**
   * Deserialize the initial editor value.
   *
   * @type {Object}
   */

  state = {
    value: this.props.value
      ? Value.fromJSON(JSON.parse(this.props.value))
      : Value.fromJSON(initialValue),
  }

  /**
   * On change, save the new `value`.
   *
   * @param {Change} change
   */

  onChange = ({ value }) => {
    const { setFieldsValue } = this.props;
    this.setState({ value });
    setFieldsValue({
      body: serializer.serialize(value),
      body_raw: JSON.stringify(value.toJSON()),
    });
  }

  /**
   * Render a mark-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type);

    return (
      <Button
        active={isActive.toString()}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <i className={`fas ${icon}`} />
      </Button>
    );
  }

  /**
   * Render a Slate node.
   *
   * @param {Object} props
   * @return {Element}
   */

   renderNode = (props) => {
     const { node } = props;

     switch (node.type) {
       case 'paragraph':
         return commonNode('p')(props);
       case 'blockquote':
         return commonNode('blockquote')(props);
       case 'emoji':
         return emojiNode()(props);
       case 'heading1':
         return commonNode('h1')(props);
       case 'heading2':
         return commonNode('h2')(props);
       case 'list-ul':
         return commonNode('ul')(props);
       case 'list-ol':
         return commonNode('ol')(props);
       case 'list-item':
         return commonNode('li')(props);
       case 'image':
         return imageNode()(props);
       case 'link':
         return linkNode()(props);
       case 'youtube':
         return videoNode('youtube')(props);
       case 'dailymotion':
         return videoNode('dailymotion')(props);
       case 'youku':
         return videoNode('youku')(props);
       case 'vimeo':
         return videoNode('vimeo')(props);
       default:
         return <noscript />;
     }
   }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark = (props) => {
    const { mark } = props;

    switch (mark.type) {
      case 'bold':
        return commonMark('strong')(props);
      case 'code':
        return commonMark('code')(props);
      case 'fontSize':
        return commonMark('span', 'fontSize')(props);
      case 'letterSpacing':
        return commonMark('span', 'letterSpacing')(props);
      case 'italic':
        return commonMark('i')(props);
      case 'strikethrough':
        return commonMark('s')(props);
      case 'underline':
        return commonMark('u')(props);
      default:
        return <noscript />;
    }
  }

  /**
   * Render.
   *
   * @return {Element}
   */

  render() {
    const menuToolbarOption = [
      { type: Undo, title: 'Undo' },
      { type: Redo, title: 'Redo' },
      'seperator',
      { type: Header1, title: 'Header One' },
      { type: Header2, title: 'Header Two' },
      { type: Header3, title: 'Header Three' },
      { type: Blockquote, title: 'Blockquote' },
      { type: Hr, title: 'Ruler' },
      'seperator',
      { type: AlignLeft, title: 'Align Left' },
      { type: AlignCenter, title: 'Align Center' },
      { type: AlignRight, title: 'Align Right' },
      { type: Indent, title: 'Indent' },
      { type: Outdent, title: 'Outdent' },
      'seperator',
      { type: OlList, title: 'Order List' },
      { type: UlList, title: 'Unorder List' },
      'seperator',
      { type: Link, title: 'Link' },
      { type: Image, title: 'Image' },
      { type: Video, title: 'Video' },
      { type: Table, title: 'Table' },
      'seperator',
      { type: 'fullScreen', title: 'Full Screen' },
      { type: 'help', title: 'Help' },
    ];
    return (
      <div className={styles.container}>
        {/* False imports to load required stylesheets */}
        {false && <Popover />}
        {false && <Form />}
        {false && <InputNumber />}
        <CannerEditor
          className={styles.editor}
          value={this.state.value}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
          onChange={this.onChange}
          galleryConfig={null}
          menuToolbarOption={menuToolbarOption}
        />
      </div>
    );
  }
}

/**
 * Export.
 */

export default ContentEditor;
