import { connect } from 'dva';
import { Form } from 'antd';
import { compose } from 'recompose';
import _ from 'lodash';
import QuestionnaireItemForm from './form';

export default compose(
  connect(({ questionnaires, loading, errors }, { questionnaireItemId }) => ({
    questionnaire: questionnaires.resource,
    questionnaireItem: _.find(questionnaires.resource.questionnaire_items, { id: questionnaireItemId }),
    loading: loading.effects['questionnaires/read'],
    submitting: loading.effects['questionnaire_items/update'] || loading.effects['questionnaire_items/delete'],
    formErrors: errors.formErrors,
    type: 'edit',
  })),
  Form.create(),
)(QuestionnaireItemForm);
