import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'dva';
import inflection from 'inflection';
import { Checkbox, Button } from 'antd';
import { PlannableComponent, PlannablePlaceholder } from '~/components/Plannable';
import _ from 'lodash';
import HorizontalTable from '~/components/HorizontalTable';
import styles from './PrintSelect.module.less';

class PrintSelect extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    activities: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(['care_plans']).isRequired,
  }

  static defaultProps = {
    loading: false,
  }

  state = {
    checkedForPrinting: [],
  }

  handleCheckAll = () => {
    const { checkedForPrinting } = this.state;
    const { activities } = this.props;
    const hasSelectedCarePlanActivities = !_.isEmpty(checkedForPrinting);
    if (hasSelectedCarePlanActivities) {
      this.setState({
        checkedForPrinting: [],
      });
    } else {
      this.setState({
        checkedForPrinting: activities.map(item => item.id),
      });
    }
  }

  handleCheck = item => () => {
    const { checkedForPrinting } = this.state;
    const { id } = item;
    let printableItems = checkedForPrinting;
    if (!_.includes(printableItems, id)) {
      printableItems = [...printableItems, id];
    } else {
      printableItems = _.without(printableItems, id);
    }
    this.setState({
      checkedForPrinting: printableItems,
    });
  }

  handlePrint = () => {
    const {
      dispatch,
      history,
      type,
    } = this.props;
    const { checkedForPrinting } = this.state;
    dispatch({
      type: `${type}/print`,
      payload: {
        params: checkedForPrinting,
      },
    });
    history.push('/print_preview/activities');
  }

  render() {
    const {
      activities,
      loading,
    } = this.props;
    const { checkedForPrinting } = this.state;

    const columns = [
      {
        title: 'Task Type',
        key: 'task_type',
        render: (text, item) => (
          _.isEmpty(item.plannable) ?
            <PlannablePlaceholder
              item={item}
            />
            :
            <PlannableComponent
              item={item}
            />
        ),
      },
      {
        title: (
          <div className={styles.selectAllWrapper}>
            <Checkbox
              className={styles.selectAll}
              onClick={this.handleCheckAll}
              checked={
                activities &&
                  activities.length === checkedForPrinting.length
              }
            >
              Print All
            </Checkbox>
          </div>
        ),
        key: 'print',
        render: item => (
          <Checkbox
            key={item.id}
            checked={_.includes(checkedForPrinting, item.id)}
            onClick={this.handleCheck(item)}
            className={styles.itemCheckBox}
          />
        ),
      },
    ];
    return (
      <Fragment>
        <HorizontalTable
          bordered={false}
          columns={columns}
          dataSource={activities}
          rowKey={record => record.id}
          loading={loading}
          onRow={record => ({
            onClick: this.handleCheck(record),
          })}
          pagination={false}
        />
        <div className={styles.printButtonWrapper}>
          <Button
            onClick={this.handlePrint}
            disabled={checkedForPrinting.length === 0}
          >
            Print preview {checkedForPrinting.length} selected {inflection.inflect('item', checkedForPrinting.length)}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default compose(
  connect(),
)(PrintSelect);
