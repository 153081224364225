import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import styles from './EmailTemplatePreview.module.less';

class EmailTemplatePreview extends Component {
  static propTypes = {
    emailTemplate: PropTypes.shape({
      title: PropTypes.string,
      name: PropTypes.string,
      subject: PropTypes.string,
    }).isRequired,
  }

  render() {
    const { emailTemplate } = this.props;
    return (
      <Card
        title={emailTemplate.title || emailTemplate.name}
        className={styles.emailTemplatePreview}
      >
        <h4>{emailTemplate.subject}</h4>
      </Card>
    );
  }
}

export default EmailTemplatePreview;
