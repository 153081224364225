import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import PractitionerForm from './PractitionerForm';

export default compose(
  connect(({ errors }) => ({
    formErrors: errors.formErrors,
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create(),
)(PractitionerForm);
