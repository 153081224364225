import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import ModularForm from './ModularForm';

const { Step } = Steps;

class StepForm extends PureComponent {
  state = {
    current: 0,
    values: {},
  };

  static propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      formFields: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        options: PropTypes.object,
        type: PropTypes.string,
      })),
      step: PropTypes.number,
    })).isRequired,
  }

  next = values => {
    const { steps, submit } = this.props;
    const current = this.state.current + 1;
    const newValues = {
      ...this.state.values,
      ...values,
    };
    if (current === steps.length) {
      submit(newValues);
    }
    this.setState({
      current,
      values: newValues,
    });
  }

  prev = values => {
    const current = this.state.current - 1;
    const newValues = {
      ...this.state.values,
      ...values,
    };
    this.setState({
      current,
      values: newValues,
    });
  }

  render() {
    const { current } = this.state;
    const { steps } = this.props;
    return (
      <div>
        <Steps size="small" current={current} style={{marginBottom: '24px'}}>
          {steps.map(item => (
            <Step key={item.label} title={item.label} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps.map(({ step, formFields }) => (
            <ModularForm
              fields={formFields}
              onOk={this.next}
              okText="Next"
              onCancel={this.prev}
              showCancel={current > 0}
              cancelText="Previous"
              type="step"
              style={step === current ? {} : { display: 'none' }}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default (StepForm);
