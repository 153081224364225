import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import TriggerEdit from '~/routes/TriggeredActions/trigger_edit';
import TriggerNew from '~/routes/TriggeredActions/trigger_new';
import FilterEdit from '~/routes/TriggeredActions/filter_edit';
import ActionEdit from '~/routes/TriggeredActions/action_edit';
import ActionNew from '~/routes/TriggeredActions/action_new';
import WorkflowEdit from '~/routes/TriggeredActions/workflow_edit';
import WorkflowNew from '~/routes/Workflows/workflow_new';

class WorkflowDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    modalType: PropTypes.oneOf(['create', 'update']).isRequired,
    workflowResourceType: PropTypes.oneOf(['Workflow', 'Trigger', 'Filter', 'Action']).isRequired,
    selectedTriggerId: PropTypes.string,
    selectedActionId: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedActionId: '',
    selectedTriggerId: '',
  }

  getTitle = () => {
    const { modalType, workflowResourceType } = this.props;
    if (workflowResourceType === 'Workflow') {
      if (modalType === 'create') {
        return `Create ${workflowResourceType}`;
      } else if (modalType === 'update') {
        return `Update ${workflowResourceType}`;
      }
    } else {
      return `Select ${workflowResourceType}`;
    }
    return '';
  }

  renderBody = () => {
    const { modalType, workflowResourceType, selectedTriggerId, selectedActionId } = this.props;
    if (modalType === 'create' && workflowResourceType === 'Workflow') {
      return <WorkflowNew onCancel={this.props.onCancel} />;
    } else if (modalType === 'update' && workflowResourceType === 'Workflow') {
      return <WorkflowEdit onCancel={this.props.onCancel} />;
    } else if (modalType === 'create' && workflowResourceType === 'Trigger') {
      return <TriggerNew />;
    } else if (modalType === 'update' && workflowResourceType === 'Trigger') {
      return <TriggerEdit triggerId={selectedTriggerId} />;
    } else if (modalType === 'update' && workflowResourceType === 'Filter') {
      return <FilterEdit triggerId={selectedTriggerId} />;
    } else if (modalType === 'create' && workflowResourceType === 'Action') {
      return <ActionNew triggerId={selectedTriggerId} />;
    } else if (modalType === 'update' && workflowResourceType === 'Action') {
      return <ActionEdit triggerId={selectedTriggerId} actionId={selectedActionId} />;
    }
    return <noscript />;
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {this.renderBody()}
      </Drawer>
    );
  }
}

export default WorkflowDrawer;
