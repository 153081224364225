import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Form } from 'antd';
import EditableTagGroup from '~/components/EditableTagGroup';

const FormItem = Form.Item;
const { Panel } = Collapse;

class ActivityTagFields extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  handleSetFieldValue = field => (value) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue({ [field]: value });
  }

  render() {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <Collapse bordered={false}>
        <Panel header="Add Details">
          <FormItem label="Region">
            {getFieldDecorator('region_tags', { initialValue: [] })(
              <EditableTagGroup
                onSetValue={this.handleSetFieldValue('region_tags')}
              />,
            )}
          </FormItem>
          <FormItem label="Custom Tags">
            {getFieldDecorator('custom_tags', { initialValue: [] })(
              <EditableTagGroup
                onSetValue={this.handleSetFieldValue('custom_tags')}
              />,
            )}
          </FormItem>
          <FormItem label="Type of Exercise">
            {getFieldDecorator('type_tags', { initialValue: [] })(
              <EditableTagGroup
                onSetValue={this.handleSetFieldValue('type_tags')}
              />,
            )}
          </FormItem>
          <FormItem label="Equipment Needed">
            {getFieldDecorator('equipment_tags', { initialValue: [] })(
              <EditableTagGroup
                onSetValue={this.handleSetFieldValue('equipment_tags')}
              />,
            )}
          </FormItem>
        </Panel>
      </Collapse>
    );
  }
}

export default ActivityTagFields;
