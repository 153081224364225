import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Divider, Dropdown, Icon } from 'antd';
import _ from 'lodash';

import styles from './TableActions.module.less';

const stopPropagation = event => event.stopPropagation();

class TableActions extends PureComponent {
  static propTypes = {
    actions: PropTypes.array,
    dropdownMenu: PropTypes.node,
  }

  static defaultProps = {
    actions: [],
    dropdownMenu: null,
  }

  renderActions = () => {
    const { actions } = this.props;
    const actionIcons = actions.map(action => (
      <Icon
        key={action.icon}
        type={action.icon}
        onClick={action.onClick}
      />
    ));
    return _.flatMap(actionIcons, (value, index, array) =>
      (array.length - 1 !== index // check for the last item
        ? [value, <Divider key={`divider ${index}`} type="vertical" />]
        : value));
  }

  renderExtraActions = () => {
    const { actions, dropdownMenu } = this.props;
    if (dropdownMenu) {
      return [
        ...(actions.length > 0 ? [(
          <Divider key="divider" type="vertical" />
        )] : []),
        (
          <Dropdown key="dropdown" overlay={dropdownMenu}>
            <Icon type="ellipsis" />
          </Dropdown>
        ),
      ];
    }
    return <noscript />;
  }

  render() {
    return (
      <div className={styles.tableActions} onClick={stopPropagation}>
        {this.renderActions()}
        {this.renderExtraActions()}
      </div>
    );
  }
}

export default TableActions;
