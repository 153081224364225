import React from 'react';
import styles from './index.module.less';

const LoginCard = ({ title, children }) => (
  <div className={styles.main}>
    <h1 className={styles.title}>{title}</h1>
    {children}
  </div>
);

export default LoginCard
;
