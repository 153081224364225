import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import _ from 'lodash';
import withResource, { resourceProps } from '~/hocs/withResource';
import QuestionnaireRenderer from '~/components/QuestionnaireRenderer';

class QuestionnairePreview extends PureComponent {
  static propTypes = {
    questionnaireId: PropTypes.string,
    questionnaires: resourceProps.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    questionnaireId: '',
  }

  componentDidUpdate(prevProps) {
    const { questionnaires, questionnaireId } = this.props;
    if (!_.isEmpty(questionnaireId) && questionnaireId !== prevProps.questionnaireId) {
      questionnaires.read(questionnaireId);
    }
  }

  render() {
    const { questionnaires, visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        width={800}
        onCancel={onCancel}
        footer={null}
        title={`Preview - ${(questionnaires.resource.title || questionnaires.resource.name || '')}`}
      >
        <Spin spinning={questionnaires.loading}>
          <QuestionnaireRenderer
            readOnly
            items={questionnaires.resource.fhir && questionnaires.resource.fhir.item}
          />
        </Spin>
      </Modal>
    );
  }
}

export default withResource('questionnaire')(QuestionnairePreview);
