import React, { PureComponent } from 'react';
import { Form, Input, Select, Button, Card } from 'antd';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

export default class ResourceForm extends PureComponent {
  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      console.log('location id:', this.props.match.params.id);
      this.props.dispatch({
        type: 'locations/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { resource, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          console.log(values);
          this.props.dispatch({
            type: 'locations/update',
            payload: {
              id: resource.id,
              params: values,
            },
          });
        } else if (type === 'new') {
          console.log(values);
          this.props.dispatch({
            type: 'locations/create',
            payload: {
              params: values,
            },
          });
        }
      }
    });
  };
  render() {
    const { resource, loading, submitting, type } = this.props;
    console.log('loading', loading);
    console.log('type', type);
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
      },
    };

    return (
      <PageHeaderLayout
        title={resource ? `Edit Location: ${resource.name}` : 'Create Location'}
        logo={
          <img alt="" src="https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" />
        }
      >
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a name',
                  },
                ],
              })(<Input placeholder="eg. location" />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Address">
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the address',
                  },
                ],
              })(<TextArea className={styles.textAreaMinHeight} placeholder="Address" rows={4} />)}
            </FormItem>

            <FormItem {...formItemLayout} label="Status">
              {getFieldDecorator('status', {
                rules: [{ required: true, message: 'Please select your status' }],
              })(
                <Select
                  placeholder="Select a option and change input text above"
                  onChange={this.handleSelectChange}
                >
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...submitFormLayout} className={styles.formItemMarginTop}>
              <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderLayout>
    );
  }
}
