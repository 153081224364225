import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, Switch } from 'antd';
import ItemOptions from '~/components/QuestionnaireDrawer/ItemOptions';
import FormError from '~/components/FormError';
import EditableTagGroup from '~/components/EditableTagGroup';
import { showConfirm } from '~/utils/utils';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;
const { Option } = Select;

export class QuestionnaireItemForm extends PureComponent {
  static propTypes = {
    questionnaire: PropTypes.object.isRequired,
    questionnaireItem: PropTypes.object,
    form: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    onCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
    questionnaireItem: null,
  }

  componentDidMount() {
    const { questionnaireItem, form: { setFieldsValue } } = this.props;

    if (questionnaireItem) {
      const { definition, required, text, item_type, questionnaire_answers, repeats, tags } = questionnaireItem;
      const vals = {
        definition,
        required,
        text,
        item_type,
        repeats,
        options: questionnaire_answers,
        tags,
      };
      setFieldsValue(vals);
    }
  }

  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { questionnaire, type, questionnaireItem } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const params = {
          item_type: values.item_type,
          definition: values.definition,
          text: values.text,
          required: values.required,
          options: values.options,
          repeats: !!values.repeats,
          readOnly: !!values.readOnly,
        };
        if (type === 'edit') {
          this.props.dispatch({
            type: 'questionnaire_items/update',
            payload: {
              parentId: questionnaire.id,
              id: questionnaireItem.id,
              params,
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'questionnaire_items/create',
            payload: {
              parentId: questionnaire.id,
              params,
              tagParams: {
                tag_name: values.tags,
              },
            },
          });
        }
      }
    });
  };

  handleDelete = () => {
    const { questionnaire, questionnaireItem } = this.props;
    this.props.dispatch({
      type: 'questionnaire_items/delete',
      payload: {
        parentId: questionnaire.id,
        id: questionnaireItem.id,
      },
    });
  }

  handleAddTag = (inputValue) => {
    const { questionnaire, questionnaireItem, type } = this.props;
    if (questionnaireItem && type === 'edit') {
      this.props.dispatch({
        type: 'resource_tags/create',
        payload: {
          id: questionnaireItem.id,
          resourceType: 'questionnaire_items',
          params: {
            tag_name: inputValue,
          },
          refetch: {
            type: 'questionnaires/read',
            payload: {
              id: questionnaire.id,
            },
          },
        },
      });
    }
  }

  handleRemoveTag = (tagName) => {
    const { questionnaire, questionnaireItem, type } = this.props;
    if (questionnaireItem && type === 'edit') {
      this.props.dispatch({
        type: 'resource_tags/delete',
        payload: {
          id: questionnaireItem.id,
          resourceType: 'questionnaire_items',
          params: {
            tag_name: tagName,
          },
          refetch: {
            type: 'questionnaires/read',
            payload: {
              id: questionnaire.id,
            },
          },
        },
      });
    }
  };

  handleSetFieldValue = field => (value) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue({ [field]: value });
  };

  renderTypeOptions = () => {
    const types = [
      'integer',
      'string',
      'text',
      'choice',
      'display',
    ];
    return types.map(type => <Option key={type} value={type}>{type}</Option>);
  }

  renderOptionsInput = () => {
    const { form, form: { getFieldValue, getFieldDecorator } } = this.props;
    const isChoice = getFieldValue('item_type') === 'choice';
    const input = isChoice ? <ItemOptions form={form} /> : <noscript />;
    return (
      <FormItem label="Options" style={isChoice ? {} : { display: 'none' }}>
        {getFieldDecorator('options', {
          rules: [
            {
              required: isChoice,
              message: 'Please enter at least one option',
            },
          ],
          initialValue: [],
        })(input)}
      </FormItem>
    );
  }

  renderRepeatsInput = () => {
    const { form: { getFieldValue, getFieldDecorator } } = this.props;
    const isChoice = getFieldValue('item_type') === 'choice';
    return (
      <FormItem label="Repeats" style={isChoice ? {} : { display: 'none' }}>
        {getFieldDecorator('repeats', {
          rules: [
            {
              required: true,
            },
          ],
          valuePropName: 'checked',
          initialValue: false,
        })(
          <Switch />,
        )}
      </FormItem>
    );
  }

  render() {
    const { submitting, type, form: { getFieldDecorator }, onCancel } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        <FormItem label="Text">
          {getFieldDecorator('text', {
            rules: [
              {
                required: true,
                message: 'Please enter text',
              },
            ],
          })(<Input placeholder="eg. What is your name?" />)}
        </FormItem>
        <FormItem label="Definition">
          {getFieldDecorator('definition', {
            rules: [
              {
                required: true,
                message: 'Please enter a definition',
              },
            ],
          })(<Input placeholder="eg. Definition" />)}
        </FormItem>
        <FormItem label="Tags">
          {getFieldDecorator('tags', { initialValue: [] })(
            <EditableTagGroup
              handleAddTag={this.handleAddTag}
              handleRemoveTag={this.handleRemoveTag}
              onSetValue={this.handleSetFieldValue('tags')}
            />,
          )}
        </FormItem>
        <FormItem label="Required">
          {getFieldDecorator('required', {
            rules: [
              {
                required: true,
              },
            ],
            valuePropName: 'checked',
            initialValue: false,
          })(
            <Switch />,
          )}
        </FormItem>
        <FormItem label="Type">
          {getFieldDecorator('item_type', {
            rules: [
              { required: true, message: 'Please select a question type' },
            ],
          })(
            <Select placeholder="Question Type">
              {this.renderTypeOptions()}
            </Select>,
          )}
        </FormItem>
        {this.renderRepeatsInput()}
        {this.renderOptionsInput()}
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button onClick={onCancel} className={styles.buttonStyles}>
            Cancel
          </Button>
          {type === 'edit' && <Button onClick={showConfirm(this.handleDelete)} type="danger" className={styles.deleteButton}>
            Delete
          </Button>}
        </FormItem>
      </Form>
    );
  }
}

export default QuestionnaireItemForm;
