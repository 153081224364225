import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import QuestionnaireItemEdit from '~/routes/Questionnaires/QuestionnaireItems/edit';
import QuestionnaireItemNew from '~/routes/Questionnaires/QuestionnaireItems/new';
import QuestionnaireNew from '~/routes/Questionnaires/new';
import QuestionnaireEdit from '~/routes/Questionnaires/edit';

export class QuestionnaireDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    modalType: PropTypes.oneOf(['create', 'update']).isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedQuestionnaireItemId: PropTypes.string,
    resourceType: PropTypes.oneOf(['Questionnaire', 'Questionnaire Item']).isRequired,
  }

  static defaultProps = {
    selectedQuestionnaireItemId: '',
  }

  getTitle = () => {
    const { modalType, resourceType } = this.props;
    if (modalType === 'create') {
      return `Create ${resourceType}`;
    } else if (modalType === 'update') {
      return `Update ${resourceType}`;
    }
    return '';
  }

  renderBody = () => {
    const { modalType, resourceType, selectedQuestionnaireItemId, onCancel } = this.props;
    if (resourceType === 'Questionnaire') {
      if (modalType === 'create') {
        return <QuestionnaireNew onCancel={onCancel} />;
      } else if (modalType === 'update') {
        return <QuestionnaireEdit onCancel={onCancel} />;
      }
    } else if (resourceType === 'Questionnaire Item') {
      if (modalType === 'create') {
        return <QuestionnaireItemNew onCancel={onCancel} />;
      } else if (modalType === 'update') {
        return <QuestionnaireItemEdit questionnaireItemId={selectedQuestionnaireItemId} onCancel={onCancel} />;
      }
    }
    return <noscript />;
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {this.renderBody()}
      </Drawer>
    );
  }
}

export default QuestionnaireDrawer;
