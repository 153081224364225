import React, { Component } from 'react';
import { Tooltip, DatePicker } from 'antd';
import PropTypes from 'prop-types';

class DatePickerWithTooltip extends Component {
  static propTypes = {
    triggerTooltip: PropTypes.string,
    placement: PropTypes.string,
    title: PropTypes.string,
  }

  static defaultProps = {
    triggerTooltip: 'focus',
    placement: 'right',
    title: 'Press enter to access',
  }

  state = {
    visible: false,
    viewed: false,
  };

  onOpenChange = (e) => {
    if (e) {
      this.setState({
        visible: false,
        viewed: true,
      });
    }
  }

  show = () => this.setState({ visible: true });

  hide = () => this.setState({ visible: false });

  render() {
    const { viewed, visible } = this.state;
    const { triggerTooltip, placement, title, ...rest } = this.props;
    return (
      <Tooltip
        visible={visible && !viewed}
        trigger={triggerTooltip}
        placement={placement}
        title={title}
        getPopupContainer={trigger => trigger.parentNode}
        overlayStyle={{ width: '100%' }}
      >
        <DatePicker
          {...rest}
          onFocus={this.show}
          onBlur={this.hide}
          onOpenChange={this.onOpenChange}
        />
      </Tooltip>
    );
  }
}

export default DatePickerWithTooltip;
