import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Spin } from 'antd';
import PractitionerEdit from './PractitionerEdit';
import PractitionerCreate from './PractitionerCreate';

export class PractitionerDrawer extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    submittingPractitionerUpdate: PropTypes.bool,
    submittingPractitionerCreate: PropTypes.bool,
    onUpdatePractitioner: PropTypes.func.isRequired,
    selectedPractitioner: PropTypes.object,
    modalType: PropTypes.string.isRequired,
    onCreatePractitioner: PropTypes.func.isRequired,
    personType: PropTypes.oneOf(['Care Team Member']).isRequired,
  }

  static defaultProps = {
    loading: false,
    selectedPractitioner: null,
    submittingPractitionerUpdate: false,
    submittingPractitionerCreate: false,
  }

  getTitle = () => {
    const { modalType, personType } = this.props;
    return modalType === 'edit' ? `Edit ${personType}` : `Create ${personType}`;
  }

  renderForm = () => {
    const {
      loading,
      onCancel,
      modalType,
      submittingPractitionerUpdate,
      submittingPractitionerCreate,
      onUpdatePractitioner,
      onCreatePractitioner,
      selectedPractitioner,
      personType,
    } = this.props;
    let form = <noscript />;
    if (modalType === 'edit') {
      form = (
        <PractitionerEdit
          selectedPractitioner={selectedPractitioner}
          onUpdatePractitioner={onUpdatePractitioner}
          submitting={submittingPractitionerUpdate}
          onCancel={onCancel}
          personType={personType}
        />
      );
    } else if (modalType === 'create') {
      form = (
        <PractitionerCreate
          onCreatePractitioner={onCreatePractitioner}
          submitting={submittingPractitionerCreate}
          onCancel={onCancel}
          personType={personType}
        />
      );
    }
    if (loading) {
      return (
        <Spin>
          {form}
        </Spin>
      );
    }
    return form;
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {this.renderForm()}
      </Drawer>
    );
  }
}

export default PractitionerDrawer;
