import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './RichTextPreview.module.less';

class RichTextPreview extends PureComponent {
  static propTypes = {
    content: PropTypes.string.isRequired,
  }
  render() {
    const { content } = this.props;
    return (
      <div className={styles.richtextPreview} dangerouslySetInnerHTML={{ __html: content }} />
    );
  }
}

export default RichTextPreview;
