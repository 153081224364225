import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getActivityImage } from '~/utils/media';
import styles from './Plannable.module.less';

class PlannablePlaceholder extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  render() {
    const { item } = this.props;
    console.warn('Missing plannable object');

    return (
      <div className={styles.taskType}>
        <img src={getActivityImage(item)} alt="Unavailable" />
        <div className={styles.description}>
          <h4>Missing Content</h4>
          <span>No content type</span>
        </div>
      </div>
    );
  }
}

export default PlannablePlaceholder;
