import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import OrganizationForm from '../OrganizationForm';

export default compose(
  connect(({ errors, organizations }) => ({
    formErrors: errors.formErrors,
    organization: organizations.resource,
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create({
    mapPropsToFields({ organization }) {
      return {
        account_id: Form.createFormField({
          value: organization.account_id,
        }),
        name: Form.createFormField({
          value: organization.name,
        }),
        address: Form.createFormField({
          value: organization.address,
        }),
        subdomain: Form.createFormField({
          value: organization.subdomain,
        }),
        address_city: Form.createFormField({
          value: organization.address_city,
        }),
        address_state: Form.createFormField({
          value: organization.address_state,
        }),
        address_postalcode: Form.createFormField({
          value: organization.address_postalcode,
        }),
      };
    },
  }),
)(OrganizationForm);
