import React, { PureComponent } from 'react';
import { Input } from 'antd';

class InputField extends PureComponent {
  render() {
    const { label, options, inputType, ...rest } = this.props;
    let InputComponent = Input;

    if (inputType === 'textarea') InputComponent = Input.TextArea;
    if (inputType === 'password') InputComponent = Input.Password;

    return (
      <InputComponent
        placeholder={label}
        autoComplete="_"
        disabled={options && options.disabled}
        type={inputType}
        {...rest}
      />
    );
  }
}

export default InputField;
