import React, { PureComponent } from 'react';
import { Form, Input, Select, Button, Card, Upload, Icon } from 'antd';

import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import styles from '~/components/ResourceList/ResourceList.module.less';
import { validateUploadFormat } from '../../utils/formValidators';

const FormItem = Form.Item;
const { Option } = Select;

export default class MediaForm extends PureComponent {
  state = {
    uploadType: 'url',
  }

  componentDidMount() {
    const { type } = this.props;
    if (type === 'edit') {
      console.log('media id:', this.props.match.params.id);
      this.props.dispatch({
        type: 'media/read',
        payload: {
          id: this.props.match.params.id,
        },
      });
    }
  }

  getContentFieldDecorator = (type) => {
    const { getFieldDecorator } = this.props.form;
    if (type === 'url') {
      return getFieldDecorator('url', {
        rules: [
          {
            required: true,
            message: 'Please input a url to an image',
          },
          {
            // validator: validateImageUrl,
          },
        ],
      })(<Input placeholder="eg. https://example.com/image.jpg" />);
    }
    return getFieldDecorator('file_data', {
      rules: [
        {
          required: true,
          message: 'Please choose a file',
        },
        {
          validator: validateUploadFormat,
        },
      ],
    })(this.renderUpload());
  }

  handleSelect = (uploadType) => {
    this.setState({ uploadType });
  }

  beforeUpload = (file) => {
    this.setState({ file });
    return false;
  }

  handleChange = (info) => {
    const fileList = info.fileList.slice(-1);
    this.setState({ fileList });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { media, type } = this.props;

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { title, content_type, url, ...otherValues } = values;
        if (type === 'edit') {
          this.props.dispatch({
            type: 'media/update',
            payload: {
              id: media.id,
              params: {
                content: {
                  title,
                  content_type,
                  url,
                },
                ...otherValues,
              },
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'media/create',
            payload: {
              params: {
                content: {
                  title,
                  content_type,
                  url,
                },
                ...otherValues,
              },
            },
          });
        }
      }
    });
  };

  renderUpload = () => {
    const uploadButton = (
      <Button>
        <Icon type="upload" /> Click to Upload
      </Button>
    );

    const imageUrl = null;

    return (
      <Upload
        name="avatar"
        multiple={false}
        showUploadList
        listType="text"
        fileList={this.state.fileList}
        action={null}
        onChange={this.handleChange}
        beforeUpload={this.beforeUpload}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
      </Upload>
    );
  };

  render() {
    const { media, loading, submitting, type } = this.props;
    console.log('loading', loading);
    console.log('type', type);
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    };

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
      },
    };

    // const { uploadType } = this.state;

    return (
      <PageHeaderLayout
        title={media ? `Edit Media: ${media.id}` : 'Create Media'}
        logo={
          <img alt="" src="https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" />
        }
      >
        <Card>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="URL">
              {/*<div>
                <Select
                  size="default"
                  defaultValue="url"
                  placeholder="Choose an upload type"
                  onChange={this.handleSelect}
                  style={{ width: 200 }}
                >
                  <Option value="url">URL</Option>
                  <Option value="upload">Upload</Option>

                </Select>
              </div> */}
              {this.getContentFieldDecorator('url')}
            </FormItem>
            <FormItem {...formItemLayout} label="Title">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a title.',
                  },
                ],
              })(<Input className={styles.maxWidth} placeholder="Title" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="MIME Type">
              {getFieldDecorator('content_type', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a MIME type.',
                  },
                ],
              })(<Input className={styles.maxWidth} placeholder="eg. image/jpeg" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Identifier">
              {getFieldDecorator('identifier', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter an identifier.',
                  },
                ],
              })(<Input className={styles.maxWidth} placeholder="Identifier" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Based On">
              {getFieldDecorator('based_on')(<Input className={styles.maxWidth} placeholder="Why this media needs to be created" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Type">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please select media type' }],
              })(
                <Select
                  placeholder="Select media type"
                  onChange={this.handleSelectChange}
                >
                  <Option value="image">Image</Option>
                  <Option value="video">Video</Option>
                  <Option value="audio">Audio</Option>
                </Select>,
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Subtype">
              {getFieldDecorator('sub_type')(<Input className={styles.maxWidth} placeholder="Subtype" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="View">
              {getFieldDecorator('view')(<Input className={styles.maxWidth} placeholder="e.g. Lateral or Antero-posterior" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Subject">
              {getFieldDecorator('subject')(<Input className={styles.maxWidth} placeholder="Who or what this Media is a record of; Patient, Practitioner" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Context">
              {getFieldDecorator('context')(<Input className={styles.maxWidth} placeholder="Encounter / Episode associated with media" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Occurence">
              {getFieldDecorator('occurence')(<Input className={styles.maxWidth} placeholder="When Media was collected" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Operator">
              {getFieldDecorator('operator')(<Input className={styles.maxWidth} placeholder="The person who generated the image (Practitioner)" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Body Site">
              {getFieldDecorator('body_site')(<Input className={styles.maxWidth} placeholder="e.g. Arm, Leg" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Device">
              {getFieldDecorator('device')(<Input className={styles.maxWidth} placeholder="Type of device used to capture media" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Note">
              {getFieldDecorator('note')(<Input className={styles.maxWidth} placeholder="Comments made about the media" />)}
            </FormItem>
            <FormItem {...submitFormLayout} className={styles.formItemMarginTop}>
              <Button onClick={this.handleSubmit} type="primary"  loading={submitting}>
                Save
              </Button>
            </FormItem>
          </Form>
        </Card>
      </PageHeaderLayout>
    );
  }
}
