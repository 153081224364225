import React, { Component } from 'react';
import PropTypes from 'prop-types';

const withBase = WrappedComponent => class extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
    }

    state = {
      isDrawerOpen: false,
      drawerType: 'create',
      addContentType: '',
      selectedActivityId: '',
    }

    handleOpenCreateDrawer = ({ key }) => {
      this.setState({
        isDrawerOpen: true,
        drawerType: 'create',
        addContentType: key,
      });
    }

    handleOpenUpdateDrawer = id => () => {
      this.setState({
        isDrawerOpen: true,
        drawerType: 'update',
        selectedActivityId: id,
      });
    }

    handleCloseDrawer = () => {
      this.setState({ isDrawerOpen: false });
    }

    handleSearch = (searchQuery, extraQueries) => {
      const { addContentType } = this.state;
      if (addContentType === 'Template') {
        this.props.dispatch({
          type: 'templates/fetch',
          payload: {
            pageNumber: 1,
            searchQuery,
            extraQueries,
          },
        });
      } else if (addContentType === 'Questionnaire' || addContentType === 'Internal Questionnaire') {
        this.props.dispatch({
          type: 'questionnaires/fetch',
          payload: {
            pageNumber: 1,
            searchQuery,
            extraQueries,
          },
        });
      } else if (addContentType === 'Education' || addContentType === 'Exercise') {
        this.props.dispatch({
          type: 'contents/fetch',
          payload: {
            pageNumber: 1,
            searchQuery,
            extraQueries: {
              ...extraQueries,
              content_type: addContentType,
            },
          },
        });
      }
    }

    render() {
      const { isDrawerOpen, drawerType, addContentType, selectedActivityId } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          onOpenCreateDrawer={this.handleOpenCreateDrawer}
          onOpenUpdateDrawer={this.handleOpenUpdateDrawer}
          onCloseDrawer={this.handleCloseDrawer}
          onSearch={this.handleSearch}
          addContentType={addContentType}
          isDrawerOpen={isDrawerOpen}
          drawerType={drawerType}
          selectedActivityId={selectedActivityId}
        />
      );
    }
};

export default withBase;
