import React, { PureComponent } from 'react';

class Display extends PureComponent {
  render() {
    return (
      <div>This question is read-only.</div>
    );
  }
}

export default Display;
