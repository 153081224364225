import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import OrganizationSettingsForm from './OrganizationSettingsForm';

export default compose(
  connect(({ errors, organizations }) => ({
    formErrors: errors.formErrors,
    organizationSettings: organizations.resource.settings,
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create({
    mapPropsToFields({ organizationSettings }) {
      return (organizationSettings && {
        app_id: Form.createFormField({
          value: organizationSettings.app_id,
        }),
        ios_app_id: Form.createFormField({
          value: organizationSettings.ios_app_id,
        }),
        android_app_id: Form.createFormField({
          value: organizationSettings.android_app_id,
        }),
        time_zone: Form.createFormField({
          value: organizationSettings.time_zone,
        }),
        primary_color: Form.createFormField({
          value: organizationSettings.primary_color,
        }),
        secondary_color: Form.createFormField({
          value: organizationSettings.secondary_color,
        }),
        primary_text: Form.createFormField({
          value: organizationSettings.primary_text,
        }),
        locale: Form.createFormField({
          value: organizationSettings.locale,
        }),
        favicon: Form.createFormField({
          value: organizationSettings.favicon,
        }),
        notification_app_id: Form.createFormField({
          value: organizationSettings.notification_app_id,
        }),
        integration_enabled: Form.createFormField({
          value: organizationSettings.integration_enabled,
        }),
        logo: Form.createFormField({
          value: organizationSettings.logo,
        }),
        logo_marketing: Form.createFormField({
          value: organizationSettings.logo_marketing,
        }),
      });
    },
  }),
)(OrganizationSettingsForm);
