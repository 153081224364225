import React from 'react';
import { Input, Select } from 'antd';
import MaskedInput from '~/components/MaskedInput';
import { validateZipCode } from '~/utils/formValidators';
import { formatZipCode } from '~/utils/utils';

const { Option } = Select;

export const usStateList = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR',
  'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export const usTimeZones = [
  {
    label: 'Pacific Time',
    value: 'America/Los_Angeles',
  },
  {
    label: 'Mountain Time',
    value: 'America/Denver',
  },
  {
    label: 'Central Time',
    value: 'America/Chicago',
  },
  {
    label: 'Eastern Time',
    value: 'America/New_York',
  },
];

export const addressFormItems = [
  {
    label: 'Address',
    fieldName: 'address',
    fieldOptions: {
      rules: [
        {
          required: true,
          message: 'Please enter the address',
        },
      ],
    },
    component: <Input placeholder="eg. 123 E 5th St" />,
  },
  {
    label: 'City',
    fieldName: 'address_city',
    fieldOptions: {},
    component: <Input placeholder="Austin" />,
  },
  {
    label: 'State',
    fieldName: 'address_state',
    fieldOptions: {},
    component: <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.props.children.includes(input.toUpperCase())}
      placeholder="Select a State"
    >
      {usStateList.map(state => <Option key={state}>{state}</Option>)}
    </Select>,
  },
  {
    label: 'Zip Code',
    fieldName: 'address_postalcode',
    fieldOptions: {
      rules: [
        { validator: validateZipCode },
      ],
      normalize: formatZipCode,
    },
    component: <MaskedInput mask="99999-9999" placeholder="12345-5678" autoComplete="_" />,
  },
];

export const sourceFormItems = [
  {
    label: 'Source ID',
    fieldName: 'source_id',
    fieldOptions: {},
    component: <Input placeholder="Source ID" />,
  },
  {
    label: 'Source',
    fieldName: 'source',
    component: <Input placeholder="eg. Clinicient" />,
  },
];
