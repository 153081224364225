import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import TemplateEdit from './TemplateEdit';
import TemplateCreate from './TemplateCreate';

class TemplateDrawer extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    onUpdateTemplate: PropTypes.func.isRequired,
    modalType: PropTypes.string.isRequired,
    onCreateTemplate: PropTypes.func,
  }

  static defaultProps = {
    submitting: false,
  }

  getTitle = () => {
    const { modalType } = this.props;
    return modalType === 'edit' ? 'Edit Template' : 'Create Template';
  }

  render() {
    const { visible, onCancel, submitting, onUpdateTemplate, onCreateTemplate, modalType } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {modalType === 'edit'
          ? (
            <TemplateEdit
              onUpdateTemplate={onUpdateTemplate}
              submitting={submitting}
              onCancel={onCancel}
            />
          ) : (
            <TemplateCreate
              onCreateTemplate={onCreateTemplate}
              submitting={submitting}
              onCancel={onCancel}
            />
          )}
      </Drawer>
    );
  }
}

export default TemplateDrawer;
