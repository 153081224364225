import { compose, mapProps } from 'recompose';
import { connect } from 'dva';
import { Form } from 'antd';
import PatientForm from './form';

export default compose(
  connect(({ errors, loading }) => ({
    formErrors: errors.formErrors,
    submitting: loading.effects['questionnaires/create']
    || loading.effects['resource_tags/create'],
  })),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'new',
  })),
)(PatientForm);
