import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import TemplateForm from './TemplateForm';

export default compose(
  connect(({ templates, errors, loading }) => ({
    template: templates.resource,
    formErrors: errors.formErrors,
    loading: loading.effects['templates/read'],
    submitting: loading.effects['templates/update'],
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create(),
)(TemplateForm);
