import React, { Component, Fragment } from 'react';

import ActivityPreview from '~/components/ActivityPreview';

const withActivityPreview = activityType => WrappedComponent => class extends Component {
    state = {
      isPreviewModalOpen: false,
      previewActivityId: '',
    }

    handleOpenPreview = id => () => {
      this.setState({
        isPreviewModalOpen: true,
        previewActivityId: id,
      });
    }

    handleClosePreview = () => {
      this.setState({ isPreviewModalOpen: false });
    }

    render() {
      const { isPreviewModalOpen, previewActivityId } = this.state;
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            onOpenActivityPreview={this.handleOpenPreview}
          />
          <ActivityPreview
            visible={isPreviewModalOpen}
            selectedActivityId={previewActivityId}
            activityType={activityType}
            onCancel={this.handleClosePreview}
          />
        </Fragment>
      );
    }
};

export default withActivityPreview;
