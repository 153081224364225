import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { Card, Button } from 'antd';
import _ from 'lodash';
import DescriptionList from '~/components/DescriptionList';
import { getBasePath } from '~/utils/router';
import { showConfirm } from '~/utils/utils';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';

import styles from './index.module.less';

const { Description } = DescriptionList;

class ResourceShow extends PureComponent {
  static propTypes = {
    onEdit: PropTypes.func,
    resourcePrefix: PropTypes.string,
    resourceName: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired,
    parentName: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
    titleKey: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    loading: PropTypes.bool,
    resource: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    extraButton: PropTypes.node,
  }

  static defaultProps = {
    onEdit: null,
    resourcePrefix: '',
    parentName: '',
    parentId: '',
    loading: false,
    extraButton: <noscript />,
  }

  componentDidMount() {
    const { resourceName, resourceId, parentId } = this.props;
    console.log(`${resourceName} id: `, resourceId);
    this.props.dispatch({
      type: `${resourceName}/read`,
      payload: {
        id: resourceId,
        parentId,
      },
    });
  }

  getTitle = () => {
    const { resource, titleKey } = this.props;
    if (typeof titleKey === 'string') {
      return _.get(resource, titleKey);
    } else if (Array.isArray(titleKey)) {
      return titleKey.map(key => _.get(resource, key)).join(' ');
    }
    return '';
  }

  handleEdit = () => {
    const { onEdit, resourcePrefix, resourceName, history, resourceId, parentName, parentId } = this.props;
    if (onEdit) {
      onEdit();
    } else {
      const basePath = getBasePath(history.location.pathname);
      if (parentName && parentId) {
        history.push(`${basePath}${parentName}/${parentId}/${resourcePrefix}${resourceName}/edit/${resourceId}`);
      } else {
        history.push(`${basePath}${resourcePrefix}${resourceName === 'organizations' ? 'account/settings/organizations' : resourceName}/edit/${resourceId}`);
      }
    }
  };

  handleDelete = () => {
    const { resourceName, resourceId, parentId } = this.props;
    this.props.dispatch({
      type: `${resourceName}/delete`,
      payload: {
        id: resourceId,
        parentId,
      },
    });
  };

  renderDescriptions = () => {
    const { resource } = this.props;
    const omittedValues = [
      'id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
    ];
    const dynamicValues = _.omit(resource, omittedValues);
    const pairs = _.map(dynamicValues, (value, key) => ({ key, value }));
    return pairs.map(item => (
      <Description key={item.key} term={_.startCase(item.key)}>
        {item.value}
      </Description>
    ));
  }

  render() {
    const { resource, extraButton, loading } = this.props;
    console.log('resource', resource);

    const description = (
      <div>
        {!loading && (
          <div>
            <DescriptionList size="small" col={2}>
              <Description term="Created Date">{resource.created_at}</Description>
              <Description term="Last Updated">{resource.updated_at}</Description>
              <Description term="Created By">{resource.created_by}</Description>
              <Description term="Last Updated By">{resource.updated_by}</Description>
              <Description term="ID">{resource.id}</Description>
            </DescriptionList>
          </div>
        )}
      </div>
    );

    const action = [
      (
        <Button onClick={this.handleEdit} type="primary">Edit</Button>
      ),
      (
        <Button onClick={showConfirm(this.handleDelete)}>Delete</Button>
      ),
      (
        extraButton
      ),
    ];

    return (
      <PageHeaderLayout wrapperClassName={styles.resourceShow}>
        <Card
          title={this.getTitle()}
          extra={action}
        >
          {description}
        </Card>
        <Card title="Details" bordered loading={loading}>
          <DescriptionList
            size="small"
            col={2}
            className={styles.descriptionList}
          >
            {this.renderDescriptions()}
          </DescriptionList>
        </Card>
      </PageHeaderLayout>
    );
  }
}

export default connect((state, { resourceName }) => ({
  resource: state[resourceName].resource,
  loading: state.loading.effects[`${resourceName}/read`],
}))(ResourceShow);
