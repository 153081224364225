import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select } from 'antd';
import FormError from '~/components/FormError';
import EditableTagGroup from '~/components/EditableTagGroup';
import styles from '~/components/ResourceList/ResourceList.module.less';
import { getItemValue } from '~/utils/utils';

const FormItem = Form.Item;
const { Option } = Select;

export default class QuestionnaireForm extends PureComponent {
  static propTypes = {
    questionnaire: PropTypes.object,
    loading: PropTypes.bool,
    submitting: PropTypes.bool,
    type: PropTypes.oneOf(['new', 'edit']),
    dispatch: PropTypes.func,
    form: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    questionnaire: null,
    loading: false,
    submitting: false,
  }

  componentDidUpdate(prevProps) {
    const { onCancel, submitting, type, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    const isEdit = type === 'edit';
    if (hasSubmitted && !hasErrors && isEdit) {
      onCancel();
    }
  }

  handleAddTag = (inputValue) => {
    const { questionnaire, type } = this.props;
    if (questionnaire && type === 'edit') {
      this.props.dispatch({
        type: 'resource_tags/create',
        payload: {
          id: questionnaire.id,
          resourceType: 'questionnaires',
          params: {
            tag_name: inputValue,
          },
          refetch: {
            type: 'questionnaires/read',
            payload: {
              id: questionnaire.id,
            },
          },
        },
      });
    }
  }

  handleRemoveTag = (tagName) => {
    const { questionnaire, type } = this.props;
    if (type === 'edit') {
      this.props.dispatch({
        type: 'resource_tags/delete',
        payload: {
          id: questionnaire.id,
          resourceType: 'questionnaires',
          params: {
            tag_name: tagName,
          },
          refetch: {
            type: 'questionnaires/read',
            payload: {
              id: questionnaire.id,
            },
          },
        },
      });
    }
  };

  handleSetFieldValue = field => (value) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue({ [field]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { questionnaire, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { tags, ...otherValues } = values;
        if (type === 'edit') {
          this.props.dispatch({
            type: 'questionnaires/update',
            payload: {
              id: questionnaire.id,
              params: {
                ...otherValues,
              },
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'questionnaires/create',
            payload: {
              params: {
                ...otherValues,
              },
              tagParams: {
                tag_name: tags,
              },
            },
          });
        }
      }
    });
  };

  render() {
    const { questionnaire, submitting, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="ant-form" layout="vertical">
        <FormError />
        <FormItem label="Title">
          {getFieldDecorator('title', {
            initialValue: getItemValue(questionnaire, 'title'),
            rules: [
              {
                required: true,
                message: 'Please enter a title',
              },
            ],
          })(<Input placeholder="eg. Bob" />)}
        </FormItem>
        <FormItem label="Status">
          {getFieldDecorator('status', {
            initialValue: getItemValue(questionnaire, 'status'),
            rules: [{ required: true, message: 'Please select a status' }],
          })(
            <Select
              placeholder="Select a option and change input text above"
            >
              <Option value="draft">Draft</Option>
              <Option value="published">Published</Option>
            </Select>,
          )}
        </FormItem>
        <FormItem label="Description">
          {getFieldDecorator('description', {
            initialValue: getItemValue(questionnaire, 'description'),
            rules: [
              {
                required: true,
                message: 'Please enter a description',
              },
            ],
          })(<Input placeholder="eg. Dobbs" />)}
        </FormItem>
        <FormItem label="Internal Survey">
          {getFieldDecorator('internal', {
            valuePropName: 'checked',
            initialValue: getItemValue(questionnaire, 'internal', false),
          })(<Input type="checkbox" />)}
        </FormItem>
        <FormItem label="Tags">
          {getFieldDecorator('tags', { initialValue: getItemValue(questionnaire, 'tags', []) })(
            <EditableTagGroup
              handleAddTag={this.handleAddTag}
              handleRemoveTag={this.handleRemoveTag}
              onSetValue={this.handleSetFieldValue('tags')}
            />,
          )}
        </FormItem>
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}
