import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import QuestionnaireForm from './form';

export default compose(
  connect(({ questionnaires, errors, loading }) => ({
    questionnaires,
    questionnaire: questionnaires.resource,
    formErrors: errors.formErrors,
    loading: loading.effects['questionnaires/read'],
    submitting: loading.effects['questionnaires/update'],
  })),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
  Form.create(),
)(QuestionnaireForm);
