import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Form, InputNumber, Select } from 'antd';
import styles from './index.module.less';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;

class ScheduleForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
  }

  componentDidMount() {
    const { form: { setFieldsValue }, trigger } = this.props;
    if (trigger && trigger.timing) {
      if (trigger.timing.when === 'schedule_at') {
        setFieldsValue({ start_date: 'assigned' });
      } else if (trigger.timing.offset) {
        setFieldsValue({
          start_date: 'after',
        });
      }
    }
  }

  handleSetFieldValue = field => (value) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue({ [field]: value });
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue }, trigger } = this.props;
    const isStartDateAfter = getFieldValue('start_date') === 'after';
    let hasValue = false;
    if (trigger && trigger.timing) {
      hasValue = trigger.timing.when || trigger.timing.offset;
    }
    const timingOptions = [
      {
        value: 'none',
        label: 'No Schedule',
      },
      {
        value: 'assigned',
        label: 'When Assigned',
      },
      {
        value: 'after',
        label: 'After',
      },
    ];
    return (
      <Collapse defaultActiveKey={hasValue ? ['1'] : []} bordered={false} className={styles.panel}>
        <Panel header="Set Schedule" key="1">
          <Col span={11}>
            <FormItem label="Action Date">
              {getFieldDecorator('start_date', {
                initialValue: 'none',
              })(
                <Select>
                  {timingOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
          {isStartDateAfter && (
            <Col span={4} offset={1}>
              <FormItem label="Amount">
                {getFieldDecorator('start_date_number', {
                  initialValue: trigger.timing.period,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a value',
                    },
                  ],
                })(
                  <InputNumber min={1} max={365} />,
                )}
              </FormItem>
            </Col>
          )}
          {isStartDateAfter && (
            <Col span={6} offset={1}>
              <FormItem label="Unit">
                {getFieldDecorator('start_date_unit', {
                  initialValue: trigger.timing.period_unit,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a unit',
                    },
                  ],
                })(
                  <Select>
                    <Option value="days">Days</Option>
                    <Option value="weeks">Weeks</Option>
                    <Option value="months">Months</Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
          )}
        </Panel>
      </Collapse>
    );
  }
}

export default ScheduleForm;
