import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select } from 'antd';
import FormError from '~/components/FormError';
import { usStateList } from '~/utils/formItems';
import MaskedInput from '~/components/MaskedInput';
import styles from '~/components/ResourceList/ResourceList.module.less';
import { validateZipCode } from '~/utils/formValidators';
import { formatZipCode } from '~/utils/utils';

const FormItem = Form.Item;
const { Option } = Select;

export class OrganizationContactForm extends PureComponent {
  static propTypes = {
    selectedOrganization: PropTypes.object,
    onUpdateOrganization: PropTypes.func,
    onCreateOrganization: PropTypes.func,
    form: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
    selectedOrganization: {},
    onUpdateOrganization: null,
    onCreateOrganization: null,
  }


  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onCreateOrganization, onUpdateOrganization, form, type } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'new') {
          onCreateOrganization({ contact: values });
        } else if (type === 'edit') {
          const { selectedOrganization: { id } } = this.props;
          onUpdateOrganization(id, { contact: values });
        }
      }
    });
  };

  renderFormItems = () => {
    const { getFieldDecorator } = this.props.form;

    const formItems = [
      {
        label: 'Website',
        fieldName: 'website',
        fieldOptions: {},
        component: <Input placeholder="Website" />,
      },
      {
        label: 'Office Email',
        fieldName: 'email',
        fieldOptions: {
          rules: [
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
          ],
        },
        component: <Input autoComplete="_" placeholder="Email" />,
      },
      {
        label: 'Office Phone',
        fieldName: 'phone',
        fieldOptions: {},
        component: <MaskedInput mask="(999) 999-9999" placeholder="(123) 456-7890" autoComplete="_" />,
      },
      {
        label: 'City',
        fieldName: 'city',
        fieldOptions: {},
        component: <Input autoComplete="_" placeholder="City" />,
      },
      {
        label: 'State',
        fieldName: 'state',
        fieldOptions: {},
        component: <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.includes(input.toUpperCase())}
          placeholder="Select a State"
        >
          {usStateList.map(state => <Option key={state}>{state}</Option>)}
        </Select>,
      },
      {
        label: 'Zip Code',
        fieldName: 'zip_code',
        fieldOptions: {
          rules: [
            { validator: validateZipCode },
          ],
          normalize: formatZipCode,
        },
        component: <MaskedInput mask="99999-9999" placeholder="12345-5678" autoComplete="_" />,
      },
    ];

    return formItems.map(({ label, fieldName, fieldOptions, component }) => (
      <FormItem label={label} key={fieldName}>
        {getFieldDecorator(fieldName, fieldOptions)(component)}
      </FormItem>
    ));
  }

  render() {
    const { submitting } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        {this.renderFormItems()}
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default OrganizationContactForm;
