import Slider from './Slider';
import Text from './Text';
import String from './String';
import Display from './Display';
import MultiChoice from './MultiChoice';
import SingleChoice from './SingleChoice';

export default {
  Slider,
  Text,
  String,
  MultiChoice,
  SingleChoice,
  Display,
};
