import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select, Checkbox, Switch } from 'antd';
import MaskedInput from '~/components/MaskedInput';
import FormError from '~/components/FormError';
import styles from '~/components/ResourceList/ResourceList.module.less';
import { validatePhone, validateZipCode } from '~/utils/formValidators';
import { getItemValue, formatZipCode } from '~/utils/utils';
import style from './index.module.less';

const FormItem = Form.Item;
const { Option } = Select;
const usStateList = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR',
  'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export class PractitionerForm extends PureComponent {
  static propTypes = {
    selectedPractitioner: PropTypes.object,
    onUpdatePractitioner: PropTypes.func,
    onCreatePractitioner: PropTypes.func,
    form: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
    selectedPractitioner: {},
    onUpdatePractitioner: null,
    onCreatePractitioner: null,
  }

  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onCreatePractitioner, onUpdatePractitioner, form, type } = this.props;
    form.validateFieldsAndScroll((err, { messaging_enabled, ...values }) => {
      if (!err) {
        if (type === 'new') {
          onCreatePractitioner({ ...values, properties: { messaging_enabled } });
        } else if (type === 'edit') {
          const { selectedPractitioner: { id } } = this.props;
          onUpdatePractitioner(id, { ...values, properties: { messaging_enabled } });
        }
      }
    });
  };

  render() {
    const { form: { getFieldDecorator }, submitting, selectedPractitioner } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        <FormItem label="First Name">
          {getFieldDecorator('given', {
            initialValue: getItemValue(selectedPractitioner, 'given'),
            rules: [
              {
                required: true,
                message: 'Please enter first name',
              },
            ],
          })(<Input placeholder="First name" autoComplete="_" />)}
        </FormItem>
        <FormItem label="Last Name">
          {getFieldDecorator('family', {
            initialValue: getItemValue(selectedPractitioner, 'family'),
            rules: [
              {
                required: true,
                message: 'Please enter last name',
              },
            ],
          })(<Input placeholder="Last name" autoComplete="_" />)}
        </FormItem>
        <FormItem label="Email">
          {getFieldDecorator('email', {
            initialValue: getItemValue(selectedPractitioner, 'email'),
            rules: [
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
              {
                required: true,
                message: 'Please enter an Email',
              },
            ],
          })(<Input placeholder="Email" autoComplete="_" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('invite_user', {
            initialValue: false,
          })(
            <Checkbox>
              Send Invite
            </Checkbox>,
          )}
        </FormItem>

        <FormItem label="Phone">
          {getFieldDecorator('phone', {
            initialValue: getItemValue(selectedPractitioner, 'phone'),
            rules: [
              { validator: validatePhone },
            ],
          })(<MaskedInput mask="(999) 999-9999" placeholder="(123) 456-7890" autoComplete="_" />)}
        </FormItem>

        <FormItem label="Active">
          {getFieldDecorator('active', {
            valuePropName: 'checked',
            initialValue: getItemValue(selectedPractitioner, 'active'),
          })(<Switch />)}
        </FormItem>

        <FormItem label="Messaging Enabled">
          {getFieldDecorator('messaging_enabled', {
            valuePropName: 'checked',
            initialValue: getItemValue(selectedPractitioner, 'messaging_enabled'),
          })(<Switch />)}
        </FormItem>

        <FormItem label="Address">
          {getFieldDecorator('address', {
            initialValue: getItemValue(selectedPractitioner, 'address'),
          })(<Input placeholder="Address" autoComplete="_" />)}
        </FormItem>

        <FormItem label="City">
          {getFieldDecorator('address_city', {
            initialValue: getItemValue(selectedPractitioner, 'address_city'),
          })(<Input placeholder="City" autoComplete="_" />)}
        </FormItem>

        <FormItem label="State">
          {getFieldDecorator('address_state', {
            initialValue: getItemValue(selectedPractitioner, 'address_state'),
          })(
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.includes(input.toUpperCase())}
              placeholder="State"
              autoComplete="_"
              className={style.select}
            >
              {usStateList.map(state => <Option key={state}>{state}</Option>)}
            </Select>,
          )}
        </FormItem>

        <FormItem label="Zip code">
          {getFieldDecorator('address_postalcode', {
            initialValue: getItemValue(selectedPractitioner, 'address_postalcode'),
            rules: [
              { validator: validateZipCode },
            ],
            normalize: formatZipCode,
          })(<MaskedInput mask="99999-9999" placeholder="12345-5678" autoComplete="_" />)}
        </FormItem>

        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default PractitionerForm;
