import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { getFhirValue } from '~/utils/questionnaire';

const RadioGroup = Radio.Group;

class SingleChoice extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  renderOptions = () => {
    const { options } = this.props;
    return options.map(option => (
      <Radio
        key={getFhirValue(option)}
        value={getFhirValue(option)}
      >
        {getFhirValue(option)}
      </Radio>
    ));
  }

  render() {
    const { options, ...otherProps } = this.props;
    return (
      <RadioGroup {...otherProps}>
        {this.renderOptions()}
      </RadioGroup>
    );
  }
}

export default SingleChoice;
