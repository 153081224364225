import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, InputNumber, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

class ActivityQuantityFields extends PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue } } = this.props;
    const freqType = getFieldValue('freq_type');
    const freqTypeSelector = (
      <Col span={7}>
        <FormItem label="Frequency Type">
          {getFieldDecorator('freq_type')(
            <Select>
              <Option value="reps">Reps Based</Option>
              <Option value="time">Time Based</Option>
            </Select>,
          )}
        </FormItem>
      </Col>
    );
    if (freqType === 'reps') {
      return (
        <FormItem>
          {freqTypeSelector}
          <Col span={8} offset={1}>
            <FormItem label="Reps">
              {getFieldDecorator('freq_reps')(
                <InputNumber min={0} max={100} />,
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Sets">
              {getFieldDecorator('freq_sets')(
                <InputNumber min={0} max={10} />,
              )}
            </FormItem>
          </Col>
        </FormItem>
      );
    } else if (freqType === 'time') {
      return (
        <FormItem>
          {freqTypeSelector}
          <Col span={5} offset={1}>
            <FormItem label="Time">
              {getFieldDecorator('freq_time')(
                <InputNumber min={0} max={100} />,
              )}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Unit">
              {getFieldDecorator('freq_unit')(
                <Select>
                  <Option value="seconds">Seconds</Option>
                  <Option value="minutes">Minutes</Option>
                  <Option value="hours">Hours</Option>
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col span={4} offset={1}>
            <FormItem label="Repeats">
              {getFieldDecorator('freq_repeats')(
                <InputNumber min={0} max={10} />,
              )}
            </FormItem>
          </Col>
        </FormItem>
      );
    }
    return <noscript />;
  }
}

export default ActivityQuantityFields;
