import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';

import OrganizationDrawer from './components/OrganizationDrawer';

export class OrganizationEdit extends Component {
  static propTypes = {
    submittingOrganizationUpdate: PropTypes.bool,
    submittingOrganizationCreate: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    organization: PropTypes.object.isRequired,
  }

  static defaultProps = {
    selectedOrganizationId: null,
    loading: false,
    submittingOrganizationUpdate: false,
    submittingOrganizationCreate: false,
  }

  state = {
    isModalOpen: false,
    modalType: 'create',
    fieldType: 'info',
  }

  openModal(modalType, id, fieldType) {
    const { organization } = this.props;
    if (id && organization && organization.id !== id) {
      this.props.dispatch({
        type: 'organizations/read',
        payload: {
          id,
        },
      });
    }
    this.setState({
      isModalOpen: true,
      modalType,
      fieldType,
    });
  }

  handleUpdateOrganization = (id, values) => {
    this.props.dispatch({
      type: 'organizations/update',
      payload: {
        id,
        params: values,
      },
    });
  }

  handleCreateOrganization = (values) => {
    this.props.dispatch({
      type: 'organizations/create',
      payload: {
        params: values,
      },
    });
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      loading,
      organization,
      submittingOrganizationUpdate,
      submittingOrganizationCreate,
    } = this.props;

    const { isModalOpen, modalType, fieldType } = this.state;

    return (
      <OrganizationDrawer
        loading={loading}
        visible={isModalOpen}
        onCancel={this.handleCloseModal}
        selectedOrganization={organization}
        onUpdateOrganization={this.handleUpdateOrganization}
        onCreateOrganization={this.handleCreateOrganization}
        submittingOrganizationUpdate={submittingOrganizationUpdate}
        submittingOrganizationCreate={submittingOrganizationCreate}
        modalType={modalType}
        fieldType={fieldType}
      />
    );
  }
}

export default connect(state => ({
  organization: state.organizations.resource,
  organizationList: state.organizations.list,
  loading: state.loading.effects['organizations/read'],
  submittingOrganizationUpdate: state.loading.effects['organizations/update'],
  submittingOrganizationCreate: state.loading.effects['organizations/create'],
}), null, null, { withRef: true })(OrganizationEdit);
