import { connect } from 'dva';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import inflection from 'inflection';

const { pluralize } = inflection;

/**
 *
 * HOC for adding resource attributes and methods as a prop.
 *
 * This function is just a convenience wrapper around redux connect.
 *
 * const resourceName = 'user'
 * const ComponentWithUsers = withResource('user')(Component);
 *
 * Then inside Component,
 * this.props.users.create(...attrs);
 *
 * You should import the props from this file:
 *
 * import { resourceProps } from 'withResource';
 * static defaultProps = { users: resourceProps }
 *
 * You can explicitly set plural form of resource, if not standard
 * withResource('octopus', { resourcesName: 'octopi' })
 *
 * You can also set actions and state props if different than regular CRUD.
 *
 * withResource('octopus', { mapStateToProps, mapDispatchToProps })
*/

const mapValueToKey = (key, method) => (...args) => {
  const result = method(...args);
  return { [key]: result };
};

const mapDispatchToProps = resourcesName => dispatch => ({
  search: ({
    pageNumber = 1,
    sortField = undefined,
    sortOrder = undefined,
    searchQuery = '',
    filters = {},
    ...rest
  } = {}) => dispatch({
    type: `${resourcesName}/fetch`,
    payload: {
      pageNumber,
      sortField,
      sortOrder,
      searchQuery,
      filters,
      ...rest,
    },
  }),
  create: (payload = {}) => dispatch({
    type: `${resourcesName}/create`,
    payload,
  }),

  read: (id, payload = {}) => dispatch({
    type: `${resourcesName}/read`,
    payload: {
      id,
      ...payload,
    },
  }),

  clear: () => dispatch({
    type: `${resourcesName}/currentResource`,
    payload: {
      data: {
        attributes: {},
      },
    },
  }),

  update: (id, payload = {}) => dispatch({
    type: `${resourcesName}/update`,
    payload: {
      id,
      ...payload,
    },
  }),

  delete: (id, payload = {}) => dispatch({
    type: `${resourcesName}/delete`,
    payload: {
      id,
      ...payload,
    },
  }),
});

const mapStateToProps = resourcesName => (state) => {
  const { [resourcesName]: { ...props } } = state;
  return {
    ...props,
    submitting: !!(
      state.loading.effects[`${resourcesName}/create`] ||
      state.loading.effects[`${resourcesName}/update`] ||
      state.loading.effects[`${resourcesName}/delete`]
    ),
    loading: !!state.loading.models[resourcesName],
  };
};

function withResource(resourceName, options = {}) {
  const resourcesName = options.resourcesName || pluralize(resourceName);

  const mapState = options.mapStateToProps || mapStateToProps(resourcesName);
  const mapDispatch = options.mapDispatchToProps || mapDispatchToProps(resourcesName);

  return compose(
    connect(
      mapValueToKey('stateProps', mapState),
      mapValueToKey('dispatchProps', mapDispatch),
    ),
    mapProps((props) => {
      const { dispatchProps, stateProps, ...rest } = props;
      return {
        ...rest,
        [resourcesName]: {
          ...stateProps,
          ...dispatchProps,
        },
      };
    }),
  );
}

export const resourceProps = PropTypes.shape({
  create: PropTypes.func,
  update: PropTypes.func,
  delete: PropTypes.func,
  search: PropTypes.func,
  read: PropTypes.func,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  resource: PropTypes.object,
  list: PropTypes.array,
});

withResource.propTypes = resourceProps;

export default withResource;
