import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Builder, Utils as QbUtils } from '@keethealth/query-builder';
import { Form, InputNumber } from 'antd';
import '@keethealth/query-builder/css/styles.scss';
import '@keethealth/query-builder/css/compact_styles.scss';
import { getSelectedOption } from '~/utils/workflows';
import config from './config'; // see below 'Config format'

const FormItem = Form.Item;

class WorkflowBuilder extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
  }

  componentDidMount() {
    const { form: { getFieldValue } } = this.props;
    this.handleQueryChange(getFieldValue('filters_tree'));
  }

  getSelectedCascaderOption = () => {
    const { options, trigger } = this.props;
    const selectedOption = getSelectedOption(options, trigger.domain, trigger.event);
    return selectedOption;
  }

  getChildren(props) {
    return (
      <div className="query-builder">
        <Builder {...props} />
      </div>
    );
  }

  handleQueryChange = (tree) => {
    const { form: { setFieldsValue } } = this.props;
    const selectedOption = this.getSelectedCascaderOption();
    const combinedConfig = { ...config, fields: selectedOption.filters };
    setFieldsValue({ filters: QbUtils.queryBuilderFormat(tree, combinedConfig) });
  }

  renderQueryBuilder = () => {
    const selectedOption = this.getSelectedCascaderOption();
    if (selectedOption) {
      return (
        <Query
          key={selectedOption.id}
          {...config}
          fields={selectedOption.filters}
          // you can pass object here, see treeJSON at onChange
          // value=transit.fromJSON(treeJSON)
          get_children={this.getChildren}
          onChange={this.handleQueryChange}
        />
      );
    }
    return <div />;
  }

  render() {
    const { form: { getFieldDecorator } } = this.props;
    // InputNumber false render is to force stylesheet to be imported
    return (
      <div>
        {false && <InputNumber />}
        <FormItem>
          {getFieldDecorator('filters_tree')(this.renderQueryBuilder())}
          {getFieldDecorator('filters')(<div />)}
        </FormItem>
      </div>
    );
  }
}

export default WorkflowBuilder;
