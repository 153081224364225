import { getPlaceholderImage } from '~/utils/placeholders';
import education from '~/assets/education.svg';
import questionnaire from '~/assets/questionnaire.svg';
import ringsLogo from '~/assets/rings-logo.png';
import _ from 'lodash';

export function parseVideo(url) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);
  let type;
  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube';
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo';
  }

  return {
    type,
    id: RegExp.$6,
  };
}

export function getVideoThumbnail(url) {
  // Obtains the video's thumbnail and passed it back to a callback function.
  const videoObj = parseVideo(url);
  if (videoObj.type === 'youtube') {
    return `//img.youtube.com/vi/${videoObj.id}/0.jpg`;
  } else if (videoObj.type === 'vimeo') {
    // Requires jQuery
    return `https://i.vimeocdn.com/video/${videoObj.id}_200x150.jpg`;
  }
  return '';
}

export function getImage(item) {
  if (_.get(item, 'properties.photo_url')) {
    return item.properties.photo_url;
  } else if (_.get(item, 'media[0].content')) {
    const media = item.media[0];
    if (media.type === 'image') {
      return media.content.url;
    } else if (media.type === 'video') {
      return getVideoThumbnail(media.content.url);
    }
  }
  return getPlaceholderImage();
}

function getLogo(type) {
  switch (type) {
    case 'Education':
      return education;
    case 'Exercise':
    case 'Questionnaire':
      return questionnaire;
    default:
      return ringsLogo;
  }
}

function getPlannableType(item) {
  if (item.plannable.content_type) {
    return item.plannable.content_type;
  }
  return item.plannable_type;
}

export function getActivityImage(item) {
  if (item.media_content && item.media_content.length > 0) {
    const mediaContent = item.media_content[0];
    if (mediaContent.type === 'image') {
      return mediaContent.url;
    } else if (mediaContent.type === 'video') {
      return getVideoThumbnail(mediaContent.url);
    }
  }
  const type = getPlannableType(item);
  return getLogo(type);
}
