import React, { Component } from 'react';
import { Tooltip, DatePicker } from 'antd';

class DatePickerWithTooltip extends Component {
  state = {
    visible: false,
    viewed: false,
  };

  show = () => this.setState({ visible: true });

  hide = () => this.setState({ visible: false });

  onOpenChange = e => {
    if (e) {
      this.setState({
        visible: false,
        viewed: true,
      });
    }
  }

  render() {
    const { viewed, visible } = this.state;
    const { triggerTooltip, placement, title, ...rest } = this.props;
    return (
      <Tooltip
        visible={visible && !viewed}
        trigger={triggerTooltip}
        placement={placement}
        title={title}
        getPopupContainer={trigger => trigger.parentNode}
        overlayStyle={{ width: '100%' }}
      >
        <DatePicker
          {...rest}
          onFocus={this.show}
          onBlur={this.hide}
          onOpenChange={this.onOpenChange}
        />
      </Tooltip>
    );
  }
}

export default DatePickerWithTooltip;
