import withResource from './withResource';

const getRefetchParams = (refetch, resourcesName, id) => {
  let params = {};

  if (refetch === true) {
    params = {
      type: `${resourcesName}/read`,
      payload: { id },
    };
  } else if (refetch) {
    params = {
      type: `${refetch.resourcesName}/read`,
      payload: {
        id: refetch.id,
      },
    };
  } else {
    return {};
  }

  return { refetch: params };
};

const createTagParams = (id, {
  tag,
  resourcesName,
  refetch,
  params,
}) => ({
  id,
  resourceType: resourcesName,
  params: {
    tag_name: tag,
    ...params,
  },
  ...getRefetchParams(refetch, resourcesName, id),
});

const deleteTagParams = (id, {
  tag,
  resourcesName,
  refetch,
  params,
}) => ({
  id,
  resourceType: resourcesName,
  params: {
    tag_name: tag,
    ...params,
  },
  ...getRefetchParams(refetch, resourcesName, id),
});

const mapStateToProps = ({ resource_tags: { resource, ...props }, loading }) => ({
  ...props,
  loading: !!loading.effects['models/resource_tags'],
});

const mapDispatchToProps = dispatch => ({
  search: ({
    pageNumber = 1,
    sortField = undefined,
    sortOrder = undefined,
    searchQuery = '',
    filters = {},
  } = {}) => dispatch({
    type: 'resource_tags/fetch',
    payload: {
      pageNumber,
      sortField,
      sortOrder,
      searchQuery,
      filters,
    },
  }),
  create: (...params) => dispatch({
    type: 'resource_tags/create',
    payload: createTagParams(...params),
  }),
  delete: (...params) => dispatch({
    type: 'resource_tags/delete',
    payload: deleteTagParams(...params),
  }),
});

export default withResource('tag', {
  mapStateToProps,
  mapDispatchToProps,
});
