import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import classNames from 'classnames';
import styles from './index.module.less';

const { Search } = Input;

class TableHeader extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func,
    right: PropTypes.node.isRequired,
    alignSearch: PropTypes.oneOf(['left', 'right']),
    hasSearch: PropTypes.bool,
  }

  static defaultProps = {
    onSearch: () => {},
    alignSearch: 'left',
    hasSearch: true,
  }

  render() {
    const { onSearch, right, alignSearch, hasSearch } = this.props;
    const className = classNames(styles.tableHeader, { [styles.searchRight]: alignSearch === 'right' });

    return (
      <div className={className}>
        {hasSearch
          ? <Search
            className={styles.search}
            placeholder="Search"
            onSearch={onSearch}
          />
          : <div />}
        <div className={styles.right}>
          {right}
        </div>
      </div>
    );
  }
}

export default TableHeader;
