import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { compose, mapProps } from 'recompose';
import { Input, Icon, AutoComplete } from 'antd';
import _ from 'lodash';
import { delayAutocompleteSearch } from '~/utils/utils';

const { Option } = AutoComplete;

class AutoCompleteField extends Component {
  static propTypes = {
    dataSource: PropTypes.array,
    displayKeys: PropTypes.oneOf(PropTypes.array, PropTypes.string),
    search: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dataSource: [],
    displayKeys: 'id',
  }

  componentDidMount() {
    this.onFocus();
  }

  onFocus = () => {
    const { dataSource } = this.props;
    if (_.isEmpty(dataSource)) {
      this.onSearch('');
    }
  }

  getDataSource = () => {
    const { dataSource } = this.props;
    const options = dataSource.map(opt => (
      <Option key={opt.id} value={opt.id}>
        {this.renderOption(opt)}
      </Option>
    ));
    return options;
  }

  onSearch = query => {
    const { search } = this.props;
    search(query);
  }

  renderOption = opt => {
    const { displayKeys } = this.props;
    let displayedLabel = '';
    if (typeof displayKeys === 'string') {
      displayedLabel = opt[displayKeys];
    } if (Array.isArray(displayKeys)) {
      displayedLabel = displayKeys.map(key => opt[key]).join(' ');
    }
    if (_.isEmpty(displayedLabel)) {
      displayedLabel = opt.id;
    }
    return displayedLabel;
  }

  render() {
    const { dataSource, search, ...rest } = this.props;

    return (
      <AutoComplete
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 300 }}
        size="large"
        style={{ width: '100%', zIndex: 0 }}
        onFocus={this.onFocus}
        dataSource={this.getDataSource()}
        onSearch={delayAutocompleteSearch(this.onSearch)}
        {...rest}
      >
        <Input
          autoComplete="_"
          suffix={<Icon type="search" />}
        />
      </AutoComplete>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => ({
      resources: state[ownProps.resourceName].list,
    }),
    (dispatch, { resourceName, filters }) => ({
      search: (query = '') => {
        dispatch({
          type: `${resourceName}/fetch`,
          payload: {
            pageNumber: 1,
            searchQuery: query,
            filters,
          },
        });
      },
    }),
  ),
  mapProps(({ resourceName, resources, filters, ...rest }) => ({
    ...rest,
    dataSource: resources,
  })),
)(AutoCompleteField);
