import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Menu, Icon } from 'antd';
import TableHeader from '~/components/TableHeader';
import withResource, { resourceProps } from '~/hocs/withResource';
import { compose } from 'recompose';
import withModal from '~/hocs/withModal';
import PrintSelect from '~/components/PrintSelect';
import _ from 'lodash';

class CarePlanActivitiesHeader extends PureComponent {
  static propTypes = {
    onOpenModal: PropTypes.func.isRequired,
    onSendCarePlan: PropTypes.func,
    hasTemplateButton: PropTypes.bool,
    patient: PropTypes.shape({
      email: PropTypes.string,
    }),
    openModal: PropTypes.func,
    history: PropTypes.object.isRequired,
    care_plans: resourceProps.isRequired,
  }

  static defaultProps = {
    onSendCarePlan: () => {},
    openModal: () => {},
    hasTemplateButton: true,
    patient: {},
  }

  handleSelectPrintTasks = () => {
    const {
      openModal,
      history,
      care_plans: { loading, resource: care_plan },
    } = this.props;

    openModal(
      'Select Care Plan Items',
      <PrintSelect
        history={history}
        activities={_.filter(care_plan.care_plan_activities, { plannable_type: 'Content' })}
        loading={loading}
        type="care_plans"
      />,
    );
  }

  render() {
    const {
      onOpenModal,
      onSendCarePlan,
      hasTemplateButton,
      patient,
    } = this.props;

    const menuItems = [
      {
        key: 'Education',
        label: 'Add Education',
      },
      // TODO: re-add
      // {
      //   key: 'Exercise',
      //   label: 'Add Exercise',
      // },
      {
        key: 'Questionnaire',
        label: 'Add Survey',
      },
      {
        key: 'Internal Questionnaire',
        label: 'Add Internal Survey',
      },
      ...hasTemplateButton ? [{
        key: 'Template',
        label: 'Apply Template',
      }] : [],
    ];

    const addMenu = (
      <Menu>
        {menuItems.map(item => (
          <Menu.Item key={item.key} onClick={onOpenModal}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );

    const headerButtons = [
      (
        <Dropdown key="dropdown" overlay={addMenu}>
          <Button>Select&hellip; <Icon type="down" /></Button>
        </Dropdown>
      ),
      ...hasTemplateButton && !_.isEmpty(patient.email) ? [(
        <Button key="send" onClick={onSendCarePlan}>
          Send Care Plan
        </Button>
      )] : [],
      (
        <Button
          key="send"
          onClick={this.handleSelectPrintTasks}
        >
           Print Care Plan Items
        </Button>
      ),
    ];
    return (
      <TableHeader
        right={headerButtons}
        hasSearch={false}
      />
    );
  }
}

export default compose(
  withModal,
  withResource('care_plan'),
)(CarePlanActivitiesHeader);
