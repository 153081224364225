import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';

class MaskedInput extends PureComponent {
  render() {
    return (
      <InputMask className="ant-input" {...this.props} />
    );
  }
}

export default MaskedInput;
