import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import _ from 'lodash';
import inflection from 'inflection';

import styles from './QuestionnairePreview.module.less';

class QuestionnairePreview extends Component {
  static propTypes = {
    questionnaire: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      item_count: PropTypes.number,
      questionnaire_items: PropTypes.array,
    }).isRequired,
  }

  getItemCount = () => {
    const { questionnaire: { item_count, questionnaire_items } } = this.props;
    if (item_count) { return item_count; }
    if (_.isArray(questionnaire_items)) { return questionnaire_items.length; }

    return 0;
  }

  render() {
    const { questionnaire: {
      title,
      description,
    } } = this.props;

    const itemCount = this.getItemCount();

    return (
      <Card
        className={styles.questionnairePreview}
        title={title}
      >
        <h4>{description}</h4>
        {itemCount
          ? `${itemCount} ${inflection.inflect('Question', itemCount)}`
          : ''
        }
      </Card>
    );
  }
}

export default QuestionnairePreview;
