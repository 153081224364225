import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Spin } from 'antd';
import _ from 'lodash';
import OrganizationInfoEdit from './info/OrganizationInfoEdit';
import OrganizationContactEdit from './contact/OrganizationContactEdit';
import OrganizationSettingsEdit from './settings/OrganizationSettingsEdit';
import OrganizationCreate from './OrganizationCreate';

export class OrganizationDrawer extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    submittingOrganizationUpdate: PropTypes.bool,
    submittingOrganizationCreate: PropTypes.bool,
    onUpdateOrganization: PropTypes.func.isRequired,
    selectedOrganization: PropTypes.object,
    modalType: PropTypes.string.isRequired,
    fieldType: PropTypes.string,
    onCreateOrganization: PropTypes.func.isRequired,
  }

  static defaultProps = {
    loading: false,
    selectedOrganization: null,
    submittingOrganizationUpdate: false,
    submittingOrganizationCreate: false,
    fieldType: 'info',
  }

  getTitle = () => {
    const { modalType, fieldType } = this.props;
    return modalType === 'edit' ? `Edit Organization ${_.capitalize(fieldType)}` : 'Create Organization';
  }

  renderForm = () => {
    const {
      loading,
      onCancel,
      modalType,
      fieldType,
      submittingOrganizationUpdate,
      submittingOrganizationCreate,
      onUpdateOrganization,
      onCreateOrganization,
      selectedOrganization,
    } = this.props;
    let form = <noscript />;
    if (modalType === 'edit') {
      switch (fieldType) {
        case 'contact':
          form = (
            <OrganizationContactEdit
              selectedOrganization={selectedOrganization}
              onUpdateOrganization={onUpdateOrganization}
              submitting={submittingOrganizationUpdate}
              onCancel={onCancel}
            />
          );
          break;
        case 'settings':
          form = (
            <OrganizationSettingsEdit
              selectedOrganization={selectedOrganization}
              onUpdateOrganization={onUpdateOrganization}
              submitting={submittingOrganizationUpdate}
              onCancel={onCancel}
            />
          );
          break;
        default:
          form = (
            <OrganizationInfoEdit
              selectedOrganization={selectedOrganization}
              onUpdateOrganization={onUpdateOrganization}
              submitting={submittingOrganizationUpdate}
              onCancel={onCancel}
            />
          );
          break;
      }
    } else if (modalType === 'create') {
      form = (
        <OrganizationCreate
          onCreateOrganization={onCreateOrganization}
          submitting={submittingOrganizationCreate}
          onCancel={onCancel}
        />
      );
    }
    if (loading) {
      return (
        <Spin>
          {form}
        </Spin>
      );
    }
    return form;
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        {this.renderForm()}
      </Drawer>
    );
  }
}

export default OrganizationDrawer;
