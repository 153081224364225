import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import Questions from '~/components/Questions';
import styles from './index.module.less';

const FormItem = Form.Item;

class QuestionnaireRenderer extends PureComponent {
  static propTypes = {
    form: PropTypes.object,
    items: PropTypes.array,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    form: null,
    items: [],
    readOnly: false,
  }

  getOptions = item => ({ rules: [{
    required: item.required,
    message: 'This question is required',
  }] })

  renderQuestion = (item) => {
    switch (item.type) {
      case 'integer':
        return (
          <Questions.Slider />
        );
      case 'string':
        return (
          <Questions.String />
        );
      case 'text':
        return (
          <Questions.Text />
        );
      case 'choice':
        return item.repeats
          ? <Questions.MultiChoice options={item.option} />
          : <Questions.SingleChoice options={item.option} />;
      case 'display':
        return (
          <Questions.Display />
        );
      default:
        return <noscript />;
    }
  }

  renderQuestions = () => {
    const { items, form, readOnly } = this.props;
    return items && items.map(item => (
      <FormItem key={item.linkId} id={item.linkId} label={item.text} className={styles.questionnaireRenderer}>
        {readOnly
          ? this.renderQuestion(item)
          : form.getFieldDecorator(item.linkId, this.getOptions(item))(this.renderQuestion(item))
        }
      </FormItem>
    ));
  }

  render() {
    return (
      <Form>
        {this.renderQuestions()}
      </Form>
    );
  }
}

export default QuestionnaireRenderer;
