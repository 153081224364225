import React from 'react';
import { Form, Input, Icon } from 'antd';
import InputWithVisibility from '~/components/InputWithVisibility';
import styles from './index.module.less';

const FormItem = Form.Item;

const LoginFormItem = ({ name, rules, icon, placeholder, type, getFieldDecorator }) => {
  let input = (
    <Input
      size="large"
      placeholder={placeholder}
      type={type}
      prefix={<Icon type={icon} className={styles.prefixIcon} />}
    />
  );

  if (type === 'password') {
    input = (
      <InputWithVisibility
        placeholder={placeholder}
        type={type}
        icon={icon}
      />
    );
  }

  return (
    <FormItem className={styles.loginFormItem} label={placeholder}>
      {getFieldDecorator(name, { rules })(input)}
    </FormItem>
  );
};

export default LoginFormItem;
