import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import { Card, Input, Button } from 'antd';
import _ from 'lodash';
import inflection from 'inflection';
import { getBasePath } from '~/utils/router';
import classNames from 'classnames';
import PageHeaderLayout from '~/layouts/PageHeaderLayout';
import HorizontalTable from '~/components/HorizontalTable';

import styles from './ResourceList.module.less';

const { Search } = Input;

class ResourcesList extends PureComponent {
  static propTypes = {
    resourceName: PropTypes.string.isRequired,
    parentName: PropTypes.string,
    parentId: PropTypes.string,
    resources: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    parentName: '',
    parentId: '',
    loading: false,
  }

  componentDidMount() {
    const { resourceName, parentId } = this.props;
    this.props.dispatch({
      type: `${resourceName}/fetch`,
      payload: {
        parentId,
        pageNumber: 1,
      },
    });
  }

  onPageChange = (page, filter, sort) => {
    const { resourceName, parentId, resources: { searchQuery } } = this.props;
    const { current } = page;

    this.props.dispatch({
      type: `${resourceName}/fetch`,
      payload: {
        parentId,
        pageNumber: current,
        sortField: sort.field,
        sortOrder: sort.order,
        searchQuery,
      },
    });
  };

  handleAdd = () => {
    const { resourceName, parentName, parentId, history } = this.props;
    const basePath = getBasePath(history.location.pathname);
    if (parentName && parentId) {
      history.push(`${basePath}${parentName}/${parentId}/${resourceName}/new`);
    } else {
      history.push(`${basePath}${resourceName}/new`);
    }
  };

  handleSearch = (searchQuery) => {
    const { resourceName, parentId } = this.props;
    this.props.dispatch({
      type: `${resourceName}/fetch`,
      payload: {
        parentId,
        pageNumber: 1,
        searchQuery,
      },
    });
  };

  renderRightButton = () => (
    <div className={styles.extraContent}>
      <Search
        className={classNames(styles.extraContentSearch, styles.searchMarginRight)}
        placeholder="Search"
        onSearch={this.handleSearch}
      />
      <Button
        type={'primary'}
        onClick={this.handleAdd}
      >
        Add {_.capitalize(inflection.singularize(this.props.resourceName))}
      </Button>
    </div>
  );

  render() {
    const {
      resourceName,
      resources: {
        list,
        pageNumber,
        pageSize,
        total,
        sortField,
        sortOrder,
      },
      columns,
      loading,
    } = this.props;
    const pagination = {
      current: pageNumber,
      pageSize,
      total,
      sortField,
      sortOrder,
    };

    const dataSource = list.map(resource => resource.attributes);

    const title = _.capitalize(resourceName);

    return (
      <PageHeaderLayout>
        <div className={styles.standardList}>
          <Card
            className={styles.listCard}
            title={title}
            extra={this.renderRightButton()}
          >
            <HorizontalTable
              bordered={false}
              columns={columns}
              dataSource={dataSource}
              rowKey={record => record.id}
              loading={loading}
              onChange={this.onPageChange}
              pagination={pagination}
            />
          </Card>
        </div>
      </PageHeaderLayout>
    );
  }
}

export default connect((state, { resourceName }) => ({
  resources: state[resourceName],
  loading: state.loading.models[resourceName],
}))(ResourcesList);
