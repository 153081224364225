import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import { getActionOptions } from '~/utils/workflows';
import FormAction from './form_action';

export default compose(
  connect(({ workflows, loading, errors }, { triggerId, actionId }) => {
    const { triggered_actions } = workflows.normalized;
    const { attributes: trigger } = triggered_actions[triggerId];

    return {
      workflow: workflows.resource,
      actionOptions: getActionOptions(workflows.options, trigger.domain, trigger.event),
      action: workflows.resource.actions.find(({ id }) => id === actionId),
      loading: loading.effects['workflows/read'],
      submitting: loading.effects['workflows/updateAction'],
      formErrors: errors.formErrors,
    };
  }),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'edit',
  })),
)(FormAction);
