import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import classNames from 'classnames';
import { getImage } from '~/utils/media';

import styles from './ContentPreview.module.less';

class ContentPreview extends Component {
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      content_type: PropTypes.string,
      description: PropTypes.string,
      media: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.shape({
          url: PropTypes.string,
        }),
      })),
    }).isRequired,
    small: PropTypes.bool,
  }

  static defaultProps = {
    small: false,
  }

  render() {
    const { content, small } = this.props;
    return (
      <Card
        className={classNames(styles.contentPreview, small && styles.small)}
      >
        <img alt="title" src={getImage(content)} />
        <div className={classNames(styles.detail, 'ant-card-meta-details')}>
          <div className="ant-card-meta-title">{content.title}</div>
          <div className="ant-card-meta-description">{content.content_type}</div>
          <div className={classNames(small ? styles.smallDescription : 'ant-card-meta-description')}>{content.description}</div>
        </div>
      </Card>
    );
  }
}

export default ContentPreview;
