import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Switch } from 'antd';
import FormError from '~/components/FormError';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;

export class OrganizationSettingsForm extends PureComponent {
  static propTypes = {
    selectedOrganization: PropTypes.object,
    onUpdateOrganization: PropTypes.func,
    onCreateOrganization: PropTypes.func,
    form: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
    selectedOrganization: {},
    onUpdateOrganization: null,
    onCreateOrganization: null,
  }

  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onCreateOrganization, onUpdateOrganization, form, type } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'new') {
          onCreateOrganization({ settings: values });
        } else if (type === 'edit') {
          const { selectedOrganization: { id } } = this.props;
          onUpdateOrganization(id, { settings: values });
        }
      }
    });
  };

  renderFormItems = () => {
    const { getFieldDecorator } = this.props.form;

    const formItems = [
      {
        label: 'App ID',
        fieldName: 'app_id',
        fieldOptions: {},
        component: <Input placeholder="Please enter an app ID" />,
      },
      {
        label: 'iOS App ID',
        fieldName: 'ios_app_id',
        fieldOptions: {},
        component: <Input placeholder="Please enter an iOS app ID" />,
      },
      {
        label: 'Android App ID',
        fieldName: 'android_app_id',
        fieldOptions: {},
        component: <Input placeholder="Please enter an iOS app ID" />,
      },
      {
        label: 'Primary Color',
        fieldName: 'primary_color',
        fieldOptions: {},
        component: <Input placeholder="Please enter a primary color" />,
      },
      {
        label: 'Secondary Color',
        fieldName: 'secondary_color',
        fieldOptions: {},
        component: <Input placeholder="Please enter a secondary color" />,
      },
      {
        label: 'Primary Text',
        fieldName: 'primary_text',
        fieldOptions: {},
        component: <Input placeholder="Please enter the primary text" />,
      },
      {
        label: 'Locale',
        fieldName: 'locale',
        fieldOptions: {},
        component: <Input placeholder="Please enter a locale" />,
      },
      {
        label: 'Favicon',
        fieldName: 'favicon',
        fieldOptions: {},
        component: <Input placeholder="Please enter a favicon" />,
      },
      {
        label: 'Notification App ID',
        fieldName: 'notification_app_id',
        fieldOptions: {},
        component: <Input placeholder="Please enter a notification app ID" />,
      },
      {
        label: 'Integration Enabled',
        fieldName: 'integration_enabled',
        fieldOptions: {
          valuePropName: 'checked',
        },
        component: <Switch />,
      },
      {
        label: 'Logo',
        fieldName: 'logo',
        fieldOptions: {},
        component: <Input placeholder="Please enter a logo" />,
      },
      {
        label: 'Marketing Logo',
        fieldName: 'logo_marketing',
        fieldOptions: {},
        component: <Input placeholder="Please enter a marketing logo" />,
      },
    ];

    return formItems.map(({ label, fieldName, fieldOptions, component }) => (
      <FormItem label={label} key={fieldName}>
        {getFieldDecorator(fieldName, fieldOptions)(component)}
      </FormItem>
    ));
  }

  render() {
    const { submitting } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        {this.renderFormItems()}
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default OrganizationSettingsForm;
