import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { Input, Icon } from 'antd';
import styles from './index.module.less';

class InputWithVisibility extends Component {
  state = {
    passwordToggle: true,
  }

  toggleVisibility = () => {
    const { passwordToggle } = this.state;
    this.setState({ passwordToggle: !passwordToggle });
  };

  render() {
    const { icon, placeholder, onChange} = this.props;
    const { passwordToggle } = this.state;
    return (
      <Fragment>
        <Input
          size="large"
          placeholder={placeholder}
          type={passwordToggle ? 'password' : 'text'}
          style={{ position: 'relative', zIndex: 1 }}
          prefix={<Icon type={icon} className={styles.prefixIcon} />}
          onChange={onChange}
        />
        <span className={styles.passwordContainer}>
          <i onClick={this.toggleVisibility} className={classNames('fas', styles.passwordIcon, (passwordToggle ? 'fa-eye' : 'fa-eye-slash'))} />
        </span>
      </Fragment>
    );
  }
}

export default InputWithVisibility;
