import _ from 'lodash';
import update from 'immutability-helper';
import { Modal } from 'antd';

const confirm = Modal.confirm;

function formatAnswer(answer, answersList = []) {
  if (_.isArray(answer)) {
    answer.map(item => formatAnswer(item, answersList));
  } else if (typeof answer === 'string') {
    answersList.push({ valueString: answer });
  } else if (typeof answer === 'number') {
    answersList.push({ valueInteger: answer });
  }
  return answersList;
}

function formatSingleItem(res, value, key) {
  let result = res;
  if (value) {
    result = update(res, {
      $push: [{
        linkId: key,
        answer: formatAnswer(value),
      }],
    });
  }
  return result;
}

export function formatQuestionnaireResponseItems(values) {
  const final = _.reduce(values, formatSingleItem, []);
  return final;
}

export function populateQuestionnaireItems(item, response = undefined) {
  if (!response) return item;

  return item.map((question) => {
    const { item: responseItems } = response;
    const matchingItem = _.find(responseItems, ['linkId', question.linkId]);
    return {
      ...question,
      answer: (matchingItem && matchingItem.answer) || undefined,
    };
  });
}

export const getOptions = (item) => {
  const initialValue = item.answer ? _.flatten(item.answer.map(_.values)) : null;
  if (!initialValue) return {};

  if (item.type === 'choice' && item.repeats) {
    return { initialValue };
  }

  return {
    initialValue: initialValue[0],
  };
};

export function isQuestionnaireAddedAndIncomplete(activities, questionnaireId) {
  const isQuestionnaireAdded = _.some(activities, { plannable_id: questionnaireId });
  if (!isQuestionnaireAdded) {
    return false;
  }
  const addedQuestionnaireActivities = _.filter(activities, { plannable_id: questionnaireId });
  const isNotStarted = _.some(addedQuestionnaireActivities, { status: 'not_started' });
  return isNotStarted;
}

export const showQuestionnaireConfirm = onOk => () => {
  confirm({
    title: 'Are you sure you want to add this Survey to this user? ',
    content: 'The user already has this survey and has NOT completed it.',
    onOk,
    onCancel() {},
    iconType: 'exclamation-circle',
  });
};

export function getFhirValue(object) {
  const objectKeys = _.keys(object);
  const valueKey = _.find(objectKeys, o => _.startsWith(o, 'value') && object[o]);
  return object[valueKey];
}
