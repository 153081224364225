import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';

import PractitionerDrawer from './components/PractitionerDrawer';

export class PractitionerEdit extends Component {
  static propTypes = {
    submittingPractitionerUpdate: PropTypes.bool,
    submittingPractitionerCreate: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    practitioner: PropTypes.object.isRequired,
  }

  static defaultProps = {
    selectedPractitionerId: null,
    loading: false,
    submittingPractitionerUpdate: false,
    submittingPractitionerCreate: false,
  }

  state = {
    isModalOpen: false,
    modalType: 'create',
  }

  openModal(modalType, id) {
    const { practitioner } = this.props;
    if (id && practitioner && practitioner.id !== id) {
      this.props.dispatch({
        type: 'practitioners/read',
        payload: {
          id,
        },
      });
    }
    this.setState({
      isModalOpen: true,
      modalType,
    });
  }

  handleUpdatePractitioner = (id, values) => {
    this.props.dispatch({
      type: 'practitioners/update',
      payload: {
        id,
        params: values,
      },
    });
  }

  handleCreatePractitioner = (values) => {
    this.props.dispatch({
      type: 'practitioners/create',
      payload: {
        params: values,
      },
    });
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      loading,
      practitioner,
      submittingPractitionerUpdate,
      submittingPractitionerCreate,
    } = this.props;

    const { isModalOpen, modalType } = this.state;

    return (
      <PractitionerDrawer
        loading={loading}
        visible={isModalOpen}
        onCancel={this.handleCloseModal}
        selectedPractitioner={practitioner}
        onUpdatePractitioner={this.handleUpdatePractitioner}
        onCreatePractitioner={this.handleCreatePractitioner}
        submittingPractitionerUpdate={submittingPractitionerUpdate}
        submittingPractitionerCreate={submittingPractitionerCreate}
        modalType={modalType}
        personType="Care Team Member"
      />
    );
  }
}

export default connect(state => ({
  practitioner: state.practitioners.resource,
  practitionerList: state.practitioners.list,
  loading: state.loading.effects['practitioners/read'],
  submittingPractitionerUpdate: state.loading.effects['practitioners/update'],
  submittingPractitionerCreate: state.loading.effects['practitioners/create'],
}), null, null, { withRef: true })(PractitionerEdit);
