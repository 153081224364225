import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input, List, Spin, Icon } from 'antd';
import _ from 'lodash';
import generateUuid from 'uuid/v1';

import { getImage } from '~/utils/media';
import { delayAutocompleteSearch } from '~/utils/utils';
import styles from './AutocompleteSearch.module.less';

const { Option } = AutoComplete;

class AutocompleteSearch extends PureComponent {
  static propTypes = {
    resourceType: PropTypes.oneOf(['Education', 'Exercise', 'Questionnaire', 'Internal Questionnaire', 'Template', 'Email Template']).isRequired,
    addedResourceIds: PropTypes.array,
    resourcesList: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    titleKey: PropTypes.string.isRequired,
    descriptionKey: PropTypes.string.isRequired,
  }

  static defaultProps = {
    addedResourceIds: [],
    loading: false,
  }

  state = {
    generatedKey: generateUuid(),
  }

  componentDidMount() {
    const { onSearch } = this.props;
    onSearch();
  }

  componentDidUpdate(prevProps) {
    const { resourceType, onSearch } = this.props;
    if (resourceType !== prevProps.resourceType) {
      onSearch();
    }
  }

  handleSelect = (value, option) => {
    this.setState({
      generatedKey: generateUuid(),
    });
    const { onSelect } = this.props;
    onSelect(value, option)();
  }

  renderAutoComplete = () => {
    const {
      addedResourceIds,
      resourceType,
      resourcesList,
      onSearch,
      titleKey,
      descriptionKey,
      loading,
    } = this.props;
    const { generatedKey } = this.state;

    const unaddedContentsList = _.filter(resourcesList, contentItem =>
      !_.includes(addedResourceIds, contentItem.id));
    const options = unaddedContentsList.map(item => (
      <Option key={item.id} title={_.get(item, titleKey)}>
        <List.Item>
          <List.Item.Meta
            avatar={resourceType === 'Content' && <img className={styles.imagePreview} alt={_.get(item, titleKey)} src={getImage(item)} />}
            title={_.get(item, titleKey)}
            description={_.get(item, descriptionKey)}
          />
        </List.Item>
      </Option>
    ));
    return (
      <Spin spinning={loading}>
        <AutoComplete
          placeholder={`Search ${resourceType}`}
          onSelect={this.handleSelect}
          onSearch={delayAutocompleteSearch(onSearch)}
          optionLabelProp={titleKey}
          dataSource={options}
          key={generatedKey}
        >
          <Input suffix={<Icon type="search" />} className={styles.input} />
        </AutoComplete>
      </Spin>
    );
  }

  render() {
    return (
      <div>
        {this.renderAutoComplete()}
      </div>
    );
  }
}

export default AutocompleteSearch;
