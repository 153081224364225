import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import _ from 'lodash';
import { Form, Radio } from 'antd';
import { compose } from 'recompose';
import AutocompleteSearch from '~/components/AutocompleteSearch';
import ScheduleForm from '~/components/ScheduleForm';
import { deserialise } from '~/utils/jsonApi/deserialise';

const FormItem = Form.Item;

class EmailTemplateAdd extends Component {
  static propTypes = {
    onSelectEmailTemplate: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    emailTemplatesList: PropTypes.array.isRequired,
    loadingEmailTemplates: PropTypes.bool,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
    form: PropTypes.object.isRequired,
  }

  static defaultProps = {
    loadingEmailTemplates: false,
    searchQuery: '',
  }

  state = {
    key: 'my_library',
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'email_templates/fetch',
      payload: {
        pageNumber: 1,
      },
    });
  }

  handleSearch = (searchQuery) => {
    const { key } = this.state;
    this.props.dispatch({
      type: 'email_templates/fetch',
      payload: {
        pageNumber: 1,
        searchQuery,
        extraQueries: {
          [key]: true,
        },
      },
    });
  }

  handleSelect = (id, option) => {
    const { onSelectEmailTemplate, emailTemplatesList } = this.props;
    const item = _.find(emailTemplatesList, ['id', id]);

    const payload = deserialise({
      data: { ...item },
    });

    return onSelectEmailTemplate(id, option, payload.data);
  }

  handleChangeRadio = (e) => {
    const { searchQuery } = this.props;
    this.setState({
      key: e.target.value,
    }, () => {
      this.handleSearch(searchQuery);
    });
  };

  render() {
    const { form, emailTemplatesList, loadingEmailTemplates, trigger } = this.props;
    return (
      <div>
        <FormItem label="Email Template Search">
          <Radio.Group onChange={this.handleChangeRadio} value={this.state.key} style={{ margin: '5px 0px 5px 0px' }}>
            <Radio value="my_library">Organization</Radio>
            <Radio value="by_default_account">Account</Radio>
          </Radio.Group>
          <AutocompleteSearch
            resourceType="Email Template"
            onSearch={this.handleSearch}
            onSelect={this.handleSelect}
            resourcesList={emailTemplatesList}
            loading={loadingEmailTemplates}
            titleKey="title"
            descriptionKey="subject"
          />
        </FormItem>
        <ScheduleForm form={form} trigger={trigger} />
      </div>
    );
  }
}

export default compose(
  connect(({ email_templates, loading }) => ({
    searchQuery: email_templates.searchQuery,
    emailTemplatesList: email_templates.list,
    loadingEmailTemplates: loading.effects['email_templates/fetch'],
  })),
  Form.create(),
)(EmailTemplateAdd);
