import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

class VideoPreview extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
  }
  render() {
    const { url } = this.props;
    return (
      <ReactPlayer
        url={url}
        width="752px"
        height="423px"
      />
    );
  }
}

export default VideoPreview;
