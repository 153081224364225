import React, { Component } from 'react';
import { Form, Button, Select } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'dva';
import FormError from '~/components/FormError';
import { getReadableCommand } from '~/utils/workflows';
import moment from 'moment';
import ParamPreview from './Actions/ParamPreview';
import ContentAdd from './Actions/content_add';
import TemplateAdd from './Actions/template_add';
import EmailTemplateAdd from './Actions/email_template_add';
import QuestionnaireAdd from './Actions/questionnaire_add';
import QuestionnaireScore from './Actions/questionnaire_score';

const FormItem = Form.Item;
const { Option } = Select;

class FormAction extends Component {
  static propTypes = {
    action: PropTypes.object,
    actionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.oneOf(['new', 'edit']).isRequired,
    triggerId: PropTypes.string.isRequired,
    submitting: PropTypes.bool,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    workflow: PropTypes.object.isRequired,
    templateActivities: PropTypes.arrayOf(PropTypes.shape({
      attributes: PropTypes.shape({
        plannable_type: PropTypes.string,
        plannable_id: PropTypes.string,
        plannable: PropTypes.shape({
          title: PropTypes.string,
        }),
      }),
    })).isRequired,
  }

  static defaultProps = {
    submitting: false,
    action: {},
  }

  componentDidMount() {
    const { action, form: { setFieldsValue } } = this.props;
    if (!_.isEmpty(action)) {
      setFieldsValue({
        command: action.command,
        params: action.params,
      });
    }
  }

  setTemplateValues = (id) => {
    const { templateActivities, form: { setFieldsValue } } = this.props;
    const mapCarePlanActivities = cpa => cpa.map(({ attributes: {
      plannable_type,
      plannable_id,
      plannable: {
        title,
      },
    } }) => ({
      plannable_type,
      plannable_id,
      title,
    }));
    setFieldsValue({ params: {
      template_id: id,
      care_plan_activities: mapCarePlanActivities(templateActivities),
    } });
  }

  updateTriggerSchedule = (values) => {
    const { triggerId, workflow } = this.props;
    const trigger = _.find(workflow.triggered_actions, { id: triggerId });

    if (values.start_date) {
      let timing = {};
      switch (values.start_date) {
        case 'after':
          timing = {
            offset: moment.duration(values.start_date_number, values.start_date_unit).asSeconds(),
            period: values.start_date_number,
            period_unit: values.start_date_unit,
          };
          break;
        case 'assigned':
          timing = {
            when: 'schedule_at',
          };
          break;
        default:
          break;
      }
      this.props.dispatch({
        type: 'workflows/updateTrigger',
        payload: {
          trigger: {
            ...trigger,
            timing,
          },
        },
      });
    }
  }

  handleSubmitTriggerAction = (e) => {
    e.preventDefault();
    const { type, triggerId, action } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          this.props.dispatch({
            type: 'workflows/updateAction',
            payload: {
              action: {
                id: action.id,
                triggered_action_id: triggerId,
                name: values.action_name,
                command: values.command,
                params: values.params,
              },
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'workflows/createAction',
            payload: {
              action: {
                triggered_action_id: triggerId,
                name: values.action_name,
                command: values.command,
                params: values.params,
              },
            },
          });
        }
        this.updateTriggerSchedule(values);
      }
    });
  };

  handleAddParams = resourceType => (id, option, item) => () => {
    const { form: { setFieldsValue }, dispatch } = this.props;
    switch (resourceType) {
      case 'Email Template':
        setFieldsValue({ params: {
          email_template_id: id,
          ...item,
        } });
        break;
      case 'Content':
        setFieldsValue({ params: {
          plannable_id: id,
          plannable_type: 'Content',
          ...item,
        } });
        break;
      case 'Template': {
        dispatch({
          type: 'template_activities/fetch',
          payload: {
            parentId: id,
          },
        }).then(() => {
          this.setTemplateValues(id);
        });
        break;
      }
      case 'Questionnaire':
        setFieldsValue({ params: {
          questionnaire_id: id,
          title: item.title,
          description: item.description,
          item_count: item.questionnaire_items.length,
        } });
        break;
      case 'QuestionnaireScore':
        setFieldsValue({ params: {
          score_type: item.score_type,
        } });
        break;
      default:
    }
  }

  renderActionSelector = () => {
    const { actionOptions } = this.props;
    return (
      <Select>
        {actionOptions.map((option) => {
          switch (option) {
            case 'Notification::Send':
            case 'Content::Add':
            case 'CarePlan::ApplyTemplate':
            case 'Questionnaire::Send':
            case 'QuestionnaireScore::Create':
              return (
                <Option key={option} value={option}>
                  {getReadableCommand(option)}
                </Option>
              );
            default:
              return '';
          }
        })}
      </Select>
    );
  }

  renderParamsSelector = () => {
    const { form, form: { getFieldValue }, action, workflow, triggerId } = this.props;
    const command = getFieldValue('command');
    const trigger = workflow.triggered_actions[_.findIndex(workflow.triggered_actions, { id: triggerId })];

    switch (command) {
      case 'Notification::Send':
        return (
          <EmailTemplateAdd onSelectEmailTemplate={this.handleAddParams('Email Template')} form={form} trigger={trigger} />
        );
      case 'Content::Add':
        return (
          <ContentAdd onSelectContent={this.handleAddParams('Content')} form={form} trigger={trigger} />
        );
      case 'CarePlan::ApplyTemplate':
        return (
          <TemplateAdd onSelectTemplate={this.handleAddParams('Template')} form={form} trigger={trigger} />
        );
      case 'Questionnaire::Send':
        return (
          <QuestionnaireAdd onSelectQuestionnaire={this.handleAddParams('Questionnaire')} form={form} trigger={trigger} />
        );
      case 'QuestionnaireScore::Create':
        return (
          <QuestionnaireScore onSelectQuestionnaireScore={this.handleAddParams('QuestionnaireScore')} form={form} scoreType={action.params ? action.params.score_type : ''} trigger={trigger} />
        );
      default:
        return <noscript />;
    }
  }

  render() {
    const { submitting, form: { getFieldDecorator, getFieldValue } } = this.props;

    return (
      <Form layout="vertical">
        <FormError />
        <FormItem label="Command">
          {getFieldDecorator('command', {
            rules: [
              {
                required: true,
                message: 'Please select an action',
              },
            ],
          })(this.renderActionSelector())}
        </FormItem>
        {this.renderParamsSelector()}
        <FormItem label="Params" style={_.isEmpty(getFieldValue('params')) ? { display: 'none' } : {}}>
          {getFieldDecorator('params', {
            initialValue: {},
            valuePropName: 'params',
          })(
            <ParamPreview />,
          )}
        </FormItem>
        <FormItem className="drawer-footer">
          <Button type="primary" onClick={this.handleSubmitTriggerAction} loading={submitting}>
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default connect(({ template_activities }) => ({
  templateActivities: template_activities.list,
}))(FormAction);
