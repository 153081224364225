import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Cascader, Input, InputNumber, Select, Form, Radio, Switch, Checkbox, Spin, Divider } from 'antd';
import _ from 'lodash';
import MaskedInput from '~/components/MaskedInput';
import FormError from '~/components/FormError';
import { showConfirm } from '~/utils/utils';
import AutoCompleteField from './components/AutoCompleteField';
import DatePickerWithTooltip from './components/DatePickerWithTooltip';
import TagField from './components/TagField';
import { mergeOptions } from './utils/utils';
import InputField from './components/InputField';
import StepForm from './StepForm';

import styles from './ModularForm.less';

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;
const CheckBoxGroup = Checkbox.Group;


class ModularForm extends PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      setFieldsValue: PropTypes.func.isRequired,
      validateFieldsAndScroll: PropTypes.func.isRequired,
      getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.object,
      type: PropTypes.string,
    })).isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    okText: PropTypes.string,
    showCancel: PropTypes.bool,
    cancelText: PropTypes.string,
    showDelete: PropTypes.bool,
    submitting: PropTypes.bool,
    spinning: PropTypes.bool,
    type: PropTypes.oneOf(['drawer', 'modal', 'page', 'step']),
  }

  static defaultProps = {
    onCancel: () => {},
    onDelete: () => {},
    okText: 'Save',
    showCancel: true,
    cancelText: 'Cancel',
    showDelete: false,
    submitting: false,
    spinning: false,
    type: 'drawer',
  }

  componentDidMount() {
    const { values, form: { setFieldsValue } } = this.props;
    if (!_.isEmpty(values)) {
      setFieldsValue(values);
    }
  }

  generateFormItem = ({ formItemLayout, hidden, label, hasFeedBack, name, options, component, step }) => {
    const { getFieldDecorator } = this.props.form;
    const fieldName = name || 'name';
    return (
      <FormItem
        {...formItemLayout}
        key={fieldName}
        label={label}
        hasFeedBack={hasFeedBack}
        step={step}
        style={hidden ? { display: 'none' } : {}}
      >
        {getFieldDecorator(fieldName, options, label)(component)}
      </FormItem>
    );
  }

  getTextField = field => (
    <span className="ant-form-text">
      {field.options && field.options.initialValue}
    </span>
  )

  getInputField = (field) => {
    const { label, options, inputType, type, ...rest } = field;
    return (
      <InputField
        placeholder={label}
        autoComplete="_"
        disabled={options && options.disabled}
        inputType={inputType}
        {...rest}
      />
    );
  }

  getDivider = () => (
    <Divider style={{ margin: '7px 0px 0px' }} type="horizontal" />
  )

  getTagField = field => (
    <TagField dataSource={field.dataSource} resource_id={field.resource_id} />
  )

  getInputNumberField = field => (
    <InputNumber
      step={field.options.step}
      formatter={field.options.formatter}
      style={{ width: '100%' }}
      min={field.min}
      max={field.max}
    />
  )

  getMaskedInput = field => (
    <MaskedInput
      mask={field.options.mask}
      autoComplete="_"
    />
  )

  getTextAreaField = field => (
    <Input
      type="textarea"
      rows={field.options.rows || 4}
      disabled={field.options.disabled}
    />
  )

  getSelectField = field => (
    <Select
      placeholder="Select"
      style={{
        width: '100%',
      }}
      disabled={field.disabled}
      multiple={field.multiple}
      showSearch
      getPopupContainer={trigger => trigger.parentNode}
      mode={field.options.mode || 'default'}
    >
      {field.items.map(({ key, value, label }) => (
        <Option key={key.toString()} value={value}>
          {label || value}
        </Option>
      ))}
    </Select>
  )

  getSelectSearchField = field => (
    <AutoCompleteField
      placeholder={field.placeholder || ''}
      resourceName={field.resourceName}
      displayKeys={field.displayKeys}
      filters={field.filters}
      disabled={field.disabled}
    />
  )

  getRadioGroupField = field => (
    <RadioGroup>
      {field.items.map(({ key, value }) => (
        <Radio key={key.toString()} value={key.toString()}>
          {value}
        </Radio>
      ))}
    </RadioGroup>
  )

  getDateField = field => (
    <DatePickerWithTooltip
      triggerTooltip="focus"
      placement="right"
      title="Press enter to access"
      placeholder="10/28/1974"
      format="MM/DD/YYYY"
    />
  )

  getDateTimeField = field => (
    <DatePickerWithTooltip
      triggerTooltip="focus"
      placement="right"
      title="Press enter to access"
      showTime
      format={field.format}
      allowClear={false}
      showToday
    />
  )

  getSwitchField = field => (
    <Switch
      checkedChildren="Yes"
      unCheckedChildren="No"
      disabled={field.options.disabled}
    />
  )

  getCheckboxField = field => (
    field.items
      ? (
        <CheckBoxGroup>
          {field.items.map(({ key, value }) => (
            <Checkbox key={key.toString()} value={key.toString()}>
              {value}
            </Checkbox>
          ))}
        </CheckBoxGroup>
      )
      : (
        <Checkbox>
          {field.label}
        </Checkbox>
      )
  )

  getUploadField = field => (
    <input
      type="file"
      ref={field.options.ref}
      disabled={field.options.disabled}
      name="patchFile"
    />
  )

  getCascaderField = field => (
    <Cascader options={field.items} />
  )

  doCancel = () => {
    const { onCancel, form } = this.props;
    onCancel && onCancel();
    form.resetFields();
  }

  doDelete = () => {
    const { onDelete, form } = this.props;
    onDelete && onDelete();
    form.resetFields();
  }

  handleOk = values => {
    const { onOk } = this.props;
    onOk && onOk(values);
  }

  handleSubmit = e => {
    const { form } = this.props;
    e && e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { onOk } = this.props;
        onOk && onOk(values);
      }
    });
  }

  generateFormFields(fields) {
    const components = [];
    const steps = [];
    for (const field of fields) {
      let component = null;
      switch (field.type) {
      case 'step':
        steps.push({
          label: field.label,
          formFields: field.fields,
          step: steps.length,
        });
        break;
      case 'input':
        component = this.getInputField(field);
        break;
      case 'divider':
        component = this.getDivider();
        break;
      case 'inputNumber':
        component = this.getInputNumberField(field);
        break;
      case 'select':
        component = this.getSelectField(field);
        break;
      case 'selectSearch':
        component = this.getSelectSearchField(field);
        break;
      case 'radioGroup':
        component = this.getRadioGroupField(field);
        break;
      case 'date':
        component = this.getDateField(field);
        break;
      case 'datetime':
        component = this.getDateTimeField(field);
        break;
      case 'switch':
        component = this.getSwitchField(field);
        break;
      case 'upload':
        component = this.getUploadField(field);
        break;
      case 'textarea':
        component = this.getTextAreaField(field);
        break;
      case 'checkbox':
        component = this.getCheckboxField(field);
        break;
      case 'maskedInput':
        component = this.getMaskedInput(field);
        break;
      case 'tagField':
        component = this.getTagField(field);
        break;
      case 'cascader':
        component = this.getCascaderField(field);
        break;
      default:
        component = this.getTextField(field);
        break;
      }
      if (component) {
        component = this.generateFormItem({
          component,
          hidden: field.hidden,
          label: field.label,
          name: field.name,
          options: mergeOptions(field.type, field.options),
          hasFeedBack: field.type === 'input',
          ...(steps.length > 0 ? { step: steps.length - 1 } : {}),
        });
        components.push(component);
      }
    }
    if (steps.length > 0) {
      return (
        <StepForm steps={steps} submit={this.handleOk} type="drawer" />
      );
    }
    return components;
  }

  renderFormButtons = () => {
    const { submitting, okText, showCancel, cancelText, showDelete, type } = this.props;
    if (type === 'drawer') {
      return (
        <FormItem className="drawer-footer">
          <Button type="primary" htmlType="submit" loading={submitting}>
            {okText}
          </Button>
          {showCancel && <Button className={styles.buttonStyles} onClick={this.doCancel}>Cancel</Button>}
          {showDelete && <Button onClick={showConfirm(this.doDelete)} type="danger" className={styles.deleteButton}>
            Delete
          </Button>}
        </FormItem>
      );
    } if (type === 'page') {
      return (
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {okText}
          </Button>
          {showDelete && <Button onClick={showConfirm(this.doDelete)} type="danger" className={styles.deleteButton}>
            Delete
          </Button>}
        </FormItem>
      );
    } if (type === 'step') {
      return (

        <FormItem>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <Button className={styles.nextPrevStyles} disabled={showCancel} onClick={this.doCancel}>
              {cancelText}
            </Button>
            <Button className={styles.nextPrevStyles} type="primary" onClick={this.handleSubmit} loading={submitting}>
              {okText}
            </Button>
          </div>
        </FormItem>

      );
    }
    return <noscript />;
  };

  render() {
    const { fields, spinning = false, loading: load, style } = this.props;

    const loading = spinning || load;

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical" style={style}>
        <FormError />
        <Spin spinning={!!loading}>
          {this.generateFormFields(fields)}
        </Spin>
        {this.renderFormButtons()}
      </Form>
    );
  }
}

export default Form.create()(ModularForm);
