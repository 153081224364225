import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form, Card } from 'antd';
import { connect } from 'dva';
import AutocompleteSearch from '~/components/AutocompleteSearch';
import { getSelectedActivity } from '~/utils/selectors';
import _ from 'lodash';

import ActivityForm from './ActivityForm';

class ActivityDrawer extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    modalType: PropTypes.oneOf(['create', 'update']).isRequired,
    addContentType: PropTypes.oneOf(['Education', 'Exercise', 'Questionnaire', 'Internal Questionnaire', 'Template', '']).isRequired,
    addedPlannableIds: PropTypes.array.isRequired,
    contentsList: PropTypes.array,
    questionnairesList: PropTypes.array,
    templatesList: PropTypes.array,
    selectedActivity: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onCreateActivity: PropTypes.func.isRequired,
    onUpdateActivity: PropTypes.func,
    onApplyTemplate: PropTypes.func,
    loadingContents: PropTypes.bool,
    loadingQuestionnaires: PropTypes.bool,
    submittingActivity: PropTypes.bool,
  }

  static defaultProps = {
    contentsList: [],
    loadingContents: false,
    questionnairesList: [],
    templatesList: [],
    selectedActivity: {},
    onUpdateActivity: () => {},
    onApplyTemplate: () => {},
    loadingQuestionnaires: false,
    submittingActivity: false,
  }

  state = {
    key: 'my_library',
  }

  componentDidMount() {
    this.props.onSearch('');
  }

  componentDidUpdate(prevProps) {
    if (this.props.addContentType !== prevProps.addContentType) {
      this.props.onSearch('');
    }
  }

  onTabChange = (key) => {
    this.setState({ key }, () => {
      this.onSearch('');
    });
  };

  onSearch = (query) => {
    const { onSearch } = this.props;
    const { key } = this.state;

    onSearch(query, {
      [key]: true,
    });
  }

  getTitle = () => {
    const { modalType, addContentType } = this.props;
    if (modalType === 'create') {
      return `Add ${addContentType}`;
    } else if (modalType === 'update') {
      return 'Update Activity';
    }
    return '';
  }

  renderWithTabs = (component) => {
    const tabList = [
      {
        tab: 'Organization',
        key: 'my_library',
      },
      {
        tab: 'Account',
        key: 'by_default_account',
      },
    ];

    return (
      <Card
        tabList={tabList}
        onTabChange={(key) => {
          this.onTabChange(key);
        }}
      >
        {component}
      </Card>
    );
  }

  renderBody = () => {
    const {
      modalType,
      addContentType, addedPlannableIds, onCreateActivity, onApplyTemplate,
      contentsList, questionnairesList, templatesList, loadingContents,
      selectedActivity, onUpdateActivity, submittingActivity, loadingQuestionnaires,
    } = this.props;
    if (modalType === 'create' && (addContentType === 'Exercise' || addContentType === 'Education')) {
      return this.renderWithTabs(
        <AutocompleteSearch
          resourceType={addContentType}
          addedResourceIds={addedPlannableIds}
          onSearch={this.onSearch}
          onSelect={onCreateActivity}
          resourcesList={contentsList}
          loading={loadingContents}
          titleKey="title"
          descriptionKey="description"
        />,
      );
    } else if (modalType === 'create' && addContentType === 'Questionnaire') {
      const nonInternalQuestionnairesList = _.reject(questionnairesList, 'internal');

      return this.renderWithTabs(
        <AutocompleteSearch
          resourceType={addContentType}
          onSearch={this.onSearch}
          onSelect={onCreateActivity}
          resourcesList={nonInternalQuestionnairesList}
          loading={loadingQuestionnaires}
          titleKey="title"
          descriptionKey="description"
        />,
      );
    } else if (modalType === 'create' && addContentType === 'Internal Questionnaire') {
      const internalQuestionnairesList = _.filter(questionnairesList, 'internal');

      return this.renderWithTabs(
        <AutocompleteSearch
          resourceType={addContentType}
          onSearch={this.onSearch}
          onSelect={onCreateActivity}
          resourcesList={internalQuestionnairesList}
          loading={loadingQuestionnaires}
          titleKey="title"
          descriptionKey="description"
        />,
      );
    } else if (modalType === 'create' && addContentType === 'Template') {
      return this.renderWithTabs(
        <AutocompleteSearch
          resourceType={addContentType}
          addedResourceIds={addedPlannableIds}
          onSearch={this.onSearch}
          onSelect={onApplyTemplate}
          resourcesList={templatesList}
          loading={loadingQuestionnaires}
          titleKey="title"
          descriptionKey="description"
        />,
      );
    } else if (modalType === 'update') {
      return (
        <ActivityForm
          selectedActivity={selectedActivity}
          onUpdateActivity={onUpdateActivity}
          submitting={submittingActivity}
        />
      );
    }
    return <noscript />;
  }

  render() {
    const {
      visible, onCancel,
    } = this.props;

    return (
      <Drawer
        title={this.getTitle()}
        visible={visible}
        onClose={onCancel}
        width="600"
        destroyOnClose
      >
        <Form layout="vertical">
          {this.renderBody()}
        </Form>
      </Drawer>
    );
  }
}

export default connect((state, props) => ({
  ...(props.activityType && props.selectedActivityId) && {
    selectedActivity: getSelectedActivity(state, props.activityType, props.selectedActivityId),
  },
}))(ActivityDrawer);
