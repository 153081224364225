import React, { PureComponent } from 'react';
import { Input } from 'antd';

class String extends PureComponent {
  render() {
    return (
      <Input {...this.props} />
    );
  }
}

export default String;
