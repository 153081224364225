import React from 'react';
import { Link } from 'dva/router';
import PageHeader from '~/components/PageHeader';
import classNames from 'classnames';
import styles from './PageHeaderLayout.module.less';

export default ({ children, wrapperClassName, top, ...restProps }) => (
  <div className={classNames(wrapperClassName, styles.layout)}>
    {top}
    <PageHeader key="pageheader" {...restProps} linkElement={Link} />
    {children ? <div className={styles.content}>{children}</div> : null}
  </div>
);
