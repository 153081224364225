import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withTags from '~/hocs/withTags';

import EditableTagGroup from '~/components/EditableTagGroup';

class TagField extends Component {
  static propTypes = {
    tags: PropTypes.shape({
      create: PropTypes.func.isRequired,
      delete: PropTypes.func.isRequired,
    }).isRequired,
    dataSource: PropTypes.string,
    resource_id: PropTypes.string,
  }

  static defaultProps = {
    dataSource: [],
    resource_id: undefined,
  }

  handleAddTag = inputValue => {
    const { resource_id, tags, dataSource } = this.props;
    if (resource_id) {
      tags.create(resource_id, { tag: inputValue, resourcesName: dataSource, refetch: true });
    }
  }

  handleRemoveTag = tagName => {
    const { resource_id, tags, dataSource } = this.props;
    if (resource_id) {
      tags.delete(resource_id, { tag: tagName, resourcesName: dataSource, refetch: true });
    }
  };

  render() {
    const { dataSource, resource_id, ...rest } = this.props;
    return (
      <EditableTagGroup
        handleAddTag={this.handleAddTag}
        handleRemoveTag={this.handleRemoveTag}
        {...rest}
      />
    );
  }
}

export default withTags(TagField);
