import _ from 'lodash';

export const getSelectedActivity = (state, activityType, selectedActivityId) =>
  _.find(state[activityType].resource.care_plan_activities, { id: selectedActivityId });

export const getSelectedQuestionnaire = (state, props) => {
  const previewActivity = _.find(state.care_plans.resource.care_plan_activities, { id: props.questionnaireActivityId });
  return previewActivity && {
    ...previewActivity.plannable.fhir,
    id: previewActivity.plannable_id,
    carePlanActivityId: props.questionnaireActivityId,
  };
};
