import _ from 'lodash';
import { validateId } from '~/utils/formValidators';

export const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

export function mergeOptions(type, options) {
  const updatedOptions = _.cloneDeep(options);
  switch (type) {
  case 'selectSearch':
    return _.mergeWith(
      updatedOptions,
      {
        rules: [
          {
            validator: validateId,
          },
        ],
      },
      customizer,
    );
  default:
    return updatedOptions;
  }
}
