import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, InputNumber, Select } from 'antd';
import update from 'immutability-helper';
import generateUuid from 'uuid/v1';
import _ from 'lodash';
import { validateInt } from '~/utils/formValidators';
import styles from './index.module.less';

const FormItem = Form.Item;
const { Option } = Select;
const InputGroup = Input.Group;

export class ItemOptions extends Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
  }

  handleAddOption = value_type => () => {
    const { form: { setFieldsValue, getFieldValue } } = this.props;
    const currentValue = getFieldValue('options');
    const uuid = generateUuid();

    let newValue = update(currentValue, {
      $push: [{
        value: '',
        value_type,
        id: uuid,
      }],
    });
    newValue = _.map(newValue, (val, index) => ({
      ...val,
      position: index,
    }));
    setFieldsValue({
      options: newValue,
    });
  }

  handleDeleteOption = id => () => {
    const { form: { setFieldsValue, getFieldValue } } = this.props;
    const currentValue = getFieldValue('options');
    const selectedIndex = _.findIndex(currentValue, n => n.id === id);

    let newValue = update(currentValue, {
      $splice: [[
        selectedIndex,
        1,
      ]],
    });
    newValue = _.map(newValue, (val, index) => ({
      ...val,
      position: index,
    }));
    setFieldsValue({
      options: newValue,
    });
  }

  handleChangeSelect = id => (selectValue) => {
    const { form: { setFieldsValue, getFieldValue } } = this.props;
    const currentValue = getFieldValue('options');
    const selectedIndex = _.findIndex(currentValue, n => n.id === id);

    const newValue = update(currentValue, {
      [selectedIndex]: {
        value_type: {
          $set: selectValue,
        },
      },
    });
    setFieldsValue({
      options: newValue,
    });
  }

  handleChangeInput = (id, key) => (event) => {
    let value = event;
    if (event && event.target) {
      value = event.target.value;
    }
    const { form: { setFieldsValue, getFieldValue } } = this.props;
    const currentValue = getFieldValue('options');
    const selectedIndex = _.findIndex(currentValue, n => n.id === id);

    const newValue = update(currentValue, {
      [selectedIndex]: {
        [key]: {
          $set: value,
        },
      },
    });
    setFieldsValue({
      options: newValue,
    });
  }

  renderTypeOptions = () => {
    const types = [
      'string',
      'integer',
    ];
    return types.map(type => <Option key={type} value={type}>{type}</Option>);
  }

  renderInput = (optionsObj) => {
    switch (optionsObj.value_type) {
      case 'integer':
        return (
          <InputNumber
            className={styles.inputNumberWidth}
            key={`input-value-${optionsObj.id}`}
            onChange={this.handleChangeInput(optionsObj.id, 'value')}
          />
        );
      case 'string':
      default:
        return (
          <Input
            className={styles.inputStringWidth}
            key={`input-value-${optionsObj.id}`}
            onChange={this.handleChangeInput(optionsObj.id, 'value')}
          />
        );
    }
  }

  render() {
    const { value, form: { getFieldDecorator } } = this.props;

    const inputs = value.map(optionsObj => (
      <InputGroup key={`input-value-${optionsObj.id}`} compact className={styles.inputs}>
        <Input
          className={styles.selectValues}
          key={`input-value-${optionsObj.id}`}
          value={optionsObj.value_type}
          disabled
        />
        <FormItem className={styles.optionsObjValue}>
          {getFieldDecorator(`input-value-${optionsObj.id}`, {
            rules: [
              {
                required: true,
                message: 'Please enter a value',
              },
            ],
            initialValue: optionsObj.value,
          })(
            this.renderInput(optionsObj),
          )}
        </FormItem>
        <FormItem className={styles.selectValues} value={optionsObj.ordinal_value}>
          {getFieldDecorator(`ordinal-value-${optionsObj.id}`, {
            rules: [
              {
                required: false,
              },
              { validator: validateInt },
            ],
            initialValue: optionsObj.ordinal_value,
          })(
            <Input
              key={`ordinal-value-${optionsObj.id}`}
              onChange={this.handleChangeInput(optionsObj.id, 'ordinal_value')}
            />,
          )}
        </FormItem>
        <Button icon="delete" onClick={this.handleDeleteOption(optionsObj.id)} />
      </InputGroup>
    ));

    return (
      <Fragment>
        {inputs}
        <Button onClick={this.handleAddOption('string')}>Add String Option</Button>
        <Button className={styles.integerButton} onClick={this.handleAddOption('integer')}>Add Integer Option</Button>
      </Fragment>
    );
  }
}

export default ItemOptions;
