import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'dva';
import _ from 'lodash';
import { Form, Select, Radio } from 'antd';
import { compose } from 'recompose';
import AutocompleteSearch from '~/components/AutocompleteSearch';
import ScheduleForm from '~/components/ScheduleForm';
import { deserialise } from '~/utils/jsonApi/deserialise';

const FormItem = Form.Item;
const { Option } = Select;

class ContentAdd extends Component {
  static propTypes = {
    onSelectContent: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    contentsList: PropTypes.array.isRequired,
    loadingContents: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    trigger: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timing: PropTypes.object.isRequired,
      active: PropTypes.bool.isRequired,
      actions: PropTypes.arrayOf(PropTypes.object).isRequired,
      domain: PropTypes.string,
      filters: PropTypes.object,
      event: PropTypes.string,
    }).isRequired,
    form: PropTypes.object.isRequired,
  }

  static defaultProps = {
    loadingContents: false,
    searchQuery: '',
  }

  state = {
    key: 'my_library',
  }

  handleSearchContent = (searchQuery) => {
    const { key } = this.state;
    const { form: { getFieldValue } } = this.props;
    const contentType = getFieldValue('contentType');
    this.props.dispatch({
      type: 'contents/fetch',
      payload: {
        pageNumber: 1,
        searchQuery,
        extraQueries: {
          contentType,
          [key]: true,
        },
      },
    });
  }

  handleSelect = (id, option) => {
    const { onSelectContent, contentsList } = this.props;
    const item = _.find(contentsList, ['id', id]);

    const payload = deserialise({
      data: { ...item },
    });

    return onSelectContent(id, option, payload.data);
  }

  handleChangeRadio = (e) => {
    const { searchQuery } = this.props;
    this.setState({
      key: e.target.value,
    }, () => {
      this.handleSearchContent(searchQuery);
    });
  };

  render() {
    const {
      contentsList,
      loadingContents,
      form,
      form: { getFieldDecorator, getFieldValue },
      trigger,
    } = this.props;
    const contentOptions = ['Education'];
    return (
      <div>
        <FormItem label="Content Type">
          {getFieldDecorator('contentType', {
            initialValue: contentOptions[0] || [],
            rules: [
              {
                required: true,
                message: 'Please select a content type',
              },
            ],
          })(
            <Select>
              {contentOptions.map(option => <Option key={option} value={option}>{option}</Option>)}
            </Select>,
          )}
        </FormItem>
        {getFieldValue('contentType') &&
        <FormItem label="Content Search">
          <Radio.Group onChange={this.handleChangeRadio} value={this.state.key} style={{ margin: '5px 0px 5px 0px' }}>
            <Radio value="my_library">Organization</Radio>
            <Radio value="by_default_account">Account</Radio>
          </Radio.Group>
          <AutocompleteSearch
            resourceType={getFieldValue('contentType')}
            onSearch={this.handleSearchContent}
            onSelect={this.handleSelect}
            resourcesList={contentsList}
            loading={loadingContents}
            titleKey="title"
            descriptionKey="description"
          />
        </FormItem>}
        <ScheduleForm form={form} trigger={trigger} />
      </div>
    );
  }
}

export default compose(
  connect(({ contents, loading }) => ({
    searchQuery: contents.searchQuery,
    contentsList: contents.list,
    loadingContents: loading.effects['contents/fetch'],
  })),
  Form.create(),
)(ContentAdd);
