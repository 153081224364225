import React, { PureComponent } from 'react';
import { Form, Button } from 'antd';
import transit from 'transit-immutable-js';
import FormError from '~/components/FormError';
import WorkflowBuilder from '~/components/WorkflowBuilder';

const FormItem = Form.Item;

export default class FormFilter extends PureComponent {
  componentDidMount() {
    const { trigger, form: { setFieldsValue } } = this.props;
    if (trigger) {
      setFieldsValue({
        filters_tree: transit.fromJSON(trigger.filters_tree),
        filters: trigger.filters,
      });
    }
  }

  handleSubmitTriggerAction = (e) => {
    e.preventDefault();
    const { workflow, trigger, triggerId } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.dispatch({
          type: 'workflows/updateTrigger',
          payload: {
            trigger: {
              id: triggerId,
              workflow_id: workflow.id,
              domain: trigger.domain,
              event: trigger.event,
              filters_tree: transit.toJSON(values.filters_tree),
              filters: values.filters,
            },
          },
        });
      }
    });
  };

  render() {
    const { workflowOptions, submitting, form, trigger } = this.props;

    return (
      <Form onSubmit={this.handleSubmitTriggerAction} layout="vertical">
        <FormError />
        <WorkflowBuilder form={form} options={workflowOptions} trigger={trigger} />
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmitTriggerAction} type="primary" loading={submitting}>
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}
