import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select, Radio, Checkbox, Switch } from 'antd';
import moment from 'moment';
import MaskedInput from '~/components/MaskedInput';
import FormError from '~/components/FormError';
import styles from '~/components/ResourceList/ResourceList.module.less';
import DatePickerWithTooltip from '~/components/DatePickerWithTooltip';
import { validateDate, validatePhone, validateZipCode } from '~/utils/formValidators';
import { formatZipCode, getItemValue } from '~/utils/utils';
import style from './index.module.less';

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;
const usStateList = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR',
  'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export class PatientForm extends PureComponent {
  static propTypes = {
    selectedPatient: PropTypes.object,
    onUpdatePatient: PropTypes.func,
    onCreatePatient: PropTypes.func,
    form: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['edit', 'new']).isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    formErrors: PropTypes.array.isRequired,
  }

  static defaultProps = {
    submitting: false,
    selectedPatient: {},
    onUpdatePatient: null,
    onCreatePatient: null,
  }


  componentDidUpdate(prevProps) {
    const { onCancel, submitting, formErrors } = this.props;
    const hasSubmitted = prevProps.submitting && !submitting;
    const hasErrors = formErrors.length > 0;
    if (hasSubmitted && !hasErrors) {
      onCancel();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onCreatePatient, onUpdatePatient, form, type } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'new') {
          onCreatePatient(values);
        } else if (type === 'edit') {
          const { selectedPatient: { id } } = this.props;
          onUpdatePatient(id, values);
        }
      }
    });
  };

  render() {
    const { form, form: { getFieldDecorator }, submitting, selectedPatient, type } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormError />
        <FormItem label="First Name">
          {getFieldDecorator('given', {
            initialValue: getItemValue(selectedPatient, 'given'),
            rules: [
              {
                required: true,
                message: 'Please enter first name',
              },
            ],
          })(<Input placeholder="First name" autoComplete="_" />)}
        </FormItem>
        <FormItem label="Last Name">
          {getFieldDecorator('family', {
            initialValue: getItemValue(selectedPatient, 'family'),
            rules: [
              {
                required: true,
                message: 'Please enter last name',
              },
            ],
          })(<Input placeholder="Last name" autoComplete="_" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('invite_user', {
            initialValue: !!getItemValue(selectedPatient, 'email'),
            valuePropName: 'checked',
          })(
            <Checkbox disabled={!!getItemValue(selectedPatient, 'email')}>
              Send Invite
            </Checkbox>,
          )}
        </FormItem>
        <FormItem label="Email">
          {getFieldDecorator('email', {
            initialValue: getItemValue(selectedPatient, 'email'),
            rules: [
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
              {
                required: form.getFieldValue('invite_user'),
                message: 'Please enter an Email',
              },
            ],
          })(<Input placeholder="Email" autoComplete="_" />)}
        </FormItem>
        <FormItem label="Date of Birth">
          {getFieldDecorator('birthdate', {
            initialValue: getItemValue(selectedPatient, 'birthdate') ? (moment(getItemValue(selectedPatient, 'birthdate'))) : '',
            rules: [
              { required: true, message: 'Please enter date of birth' },
              { validator: validateDate },
            ],
          })(
            <DatePickerWithTooltip
              placeholder="10/28/1974"
              format="MM/DD/YYYY"
            />,
          )}
        </FormItem>
        <FormItem label="Gender">
          {getFieldDecorator('gender', {
            initialValue: getItemValue(selectedPatient, 'gender'),
            rules: [{ required: true, message: 'Please select a gender' }],
          })(
            <RadioGroup>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </RadioGroup>,
          )}
        </FormItem>

        <FormItem label="Cell Phone">
          {getFieldDecorator('phone', {
            initialValue: getItemValue(selectedPatient, 'phone'),
            rules: [
              { required: true, message: 'Please enter a cell phone number' },
              { validator: validatePhone },
            ],
          })(<MaskedInput mask="(999) 999-9999" placeholder="(123) 456-7890" autoComplete="_" />)}
        </FormItem>

        <FormItem label="Address">
          {getFieldDecorator('address', {
            initialValue: getItemValue(selectedPatient, 'address'),
          })(<Input placeholder="Address" autoComplete="_" />)}
        </FormItem>

        <FormItem label="City">
          {getFieldDecorator('address_city', {
            initialValue: getItemValue(selectedPatient, 'address_city'),
          })(<Input placeholder="City" autoComplete="_" />)}
        </FormItem>

        <FormItem label="State">
          {getFieldDecorator('address_state', {
            initialValue: getItemValue(selectedPatient, 'address_state'),
          })(
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.includes(input.toUpperCase())}
              placeholder="State"
              autoComplete="_"
              className={style.select}
            >
              {usStateList.map(state => <Option key={state}>{state}</Option>)}
            </Select>,
          )}
        </FormItem>

        <FormItem label="Zip code">
          {getFieldDecorator('address_postalcode', {
            initialValue: getItemValue(selectedPatient, 'address_postalcode'),
            rules: [
              {
                required: true,
                message: 'Please enter a zip code',
              },
              { validator: validateZipCode },
            ],
            normalize: formatZipCode,
          })(<MaskedInput mask="99999-9999" placeholder="12345-5678" autoComplete="_" />)}
        </FormItem>

        {type === 'edit' &&
          <FormItem label="Active">
            {getFieldDecorator('active', {
              rules: [
                {
                  required: true,
                },
              ],
              valuePropName: 'checked',
              initialValue: getItemValue(selectedPatient, 'active'),
            })(
              <Switch />,
            )}
          </FormItem>
        }

        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmit} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default PatientForm;
