import React, { PureComponent } from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

class Text extends PureComponent {
  render() {
    return (
      <TextArea
        {...this.props}
        autosize={{ minRows: 2, maxRows: 6 }}
      />
    );
  }
}

export default Text;
