import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tag, Tooltip, Icon, Spin, AutoComplete, Input } from 'antd';
import _ from 'lodash';
import { connect } from 'dva';
import classNames from 'classnames';
import { delaySearch } from '~/utils/utils';
import styles from './index.module.less';

class EditableTagGroup extends PureComponent {
  static propTypes = {
    tagsList: PropTypes.array.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    handleAddTag: PropTypes.func.isRequired,
    handleRemoveTag: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  state = {
    placeholderVisible: true,
    inputValue: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'resource_tags/fetch',
      payload: {
        pageNumber: 1,
      },
    });
  }

  handleClose = removedTag => () => {
    const { value, onChange, handleRemoveTag, disabled } = this.props;
    if (!disabled) {
      const tags = value.filter(tag => tag !== removedTag);
      onChange(tags);
      handleRemoveTag(removedTag);
    }
  }

  hidePlaceHolder = () => {
    const { dispatch, disabled } = this.props;
    if (!disabled) {
      this.setState({
        placeholderVisible: false,
        inputValue: '',
      });
      dispatch({
        type: 'resource_tags/fetch',
        payload: {
          pageNumber: 1,
        },
      });
    }
  }

  handleInputChange = (e) => {
    const { dispatch } = this.props;

    if (e.includes(',')) {
      this.handleInputConfirm();
    } else if (_.isString(e)) {
      this.setState({ inputValue: e });
      if (e === '') {
        dispatch({
          type: 'resource_tags/fetch',
          payload: {
            pageNumber: 1,
          },
        });
      }
    }
  }

  handleSelect = (inputValue) => {
    const { value, handleAddTag, onChange } = this.props;
    if (!_.isEmpty(inputValue)) {
      let tags = value;
      if (!_.includes(tags, inputValue)) {
        tags = [...tags, inputValue];
      }
      onChange(tags);
      handleAddTag(inputValue);
      this.input.blur();
    }
    this.setState({
      placeholderVisible: true,
      inputValue: '',
    });
  }

  handleInputConfirm = () => {
    const { value, handleAddTag, onChange } = this.props;
    const { inputValue } = this.state;
    if (!_.isEmpty(inputValue)) {
      let tags = value;
      if (!_.includes(tags, inputValue)) {
        tags = [...tags, inputValue];
      }
      onChange(tags);
      handleAddTag(inputValue);
      this.input.blur();
    }
    this.setState({
      placeholderVisible: true,
      inputValue: '',
    });
  }

  saveInputRef = (input) => {
    this.input = input;
    if (this.input) {
      this.input.focus();
    }
  }

  handleSearch = (searchQuery) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'resource_tags/fetch',
      payload: {
        pageNumber: 1,
        searchQuery,
      },
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.hidePlaceHolder();
    }
  }

  renderTag = (tag) => {
    const { disabled } = this.props;
    const isLongTag = tag.length > 20;
    const tagElem = (
      <Tag key={tag} closable={!disabled} onClose={this.handleClose(tag)}>
        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
      </Tag>
    );
    return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
  }

  render() {
    const { value } = this.props;

    if (!value) {
      return (
        <div className="spinWrapper">
          <Spin size="small" />
        </div>
      );
    }
    const { tagsList } = this.props;
    const { placeholderVisible, inputValue } = this.state;
    const dataSource = tagsList.map(a => a.attributes.name).filter(el => !value.includes(el));

    return value
      ? (
        <div className={styles.tags}>
          {value.map(this.renderTag)}
          <div className={classNames(styles.autoComplete, styles.input)}>
            {!placeholderVisible && (
              <AutoComplete
                dataSource={dataSource}
                filterOption={(input, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                ref={this.saveInputRef}
                type="text"
                size="small"
                value={inputValue}
                onChange={this.handleInputChange}
                onBlur={this.handleInputConfirm}
                onSelect={this.handleSelect}
                defaultActiveFirstOption={false}
                open={!placeholderVisible}
                dropdownClassName="tag-dropdown"
              >
                <Input
                  onPressEnter={this.handleInputConfirm}
                  onKeyPress={delaySearch(this.handleSearch, 2)}
                />
              </AutoComplete>
            )}
            {placeholderVisible && (
              <Tag
                className={styles.tag}
                onClick={this.hidePlaceHolder}
                onKeyDown={this.handleKeyDown}
                tabindex="0"
              >
                <Icon type="plus" />&nbsp;New Tag
              </Tag>
            )}
          </div>
        </div>
      ) : (
        <div className="spinWrapper">
          <Spin size="small" />
        </div>
      );
  }
}

export default connect(state => ({
  tagsList: state.resource_tags.list,
  loading: state.loading.models.resource_tags,
}))(EditableTagGroup);
