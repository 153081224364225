import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import FormError from '~/components/FormError';
import { showConfirm } from '~/utils/utils';
import styles from '~/components/ResourceList/ResourceList.module.less';

const FormItem = Form.Item;

export default class FormWorkflow extends PureComponent {
  static propTypes = {
    workflow: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
  }

  componentDidMount() {
    const { workflow, form: { setFieldsValue } } = this.props;
    if (workflow) {
      setFieldsValue({
        name: workflow.name,
      });
    }
  }

  handleSubmitWorkflow = (e) => {
    e.preventDefault();
    const { workflow, type } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'edit') {
          this.props.dispatch({
            type: 'workflows/update',
            payload: {
              id: workflow.id,
              params: values,
            },
          });
        } else if (type === 'new') {
          this.props.dispatch({
            type: 'workflows/create',
            payload: {
              params: values,
            },
          });
        }
      }
    });
  };

  handleDelete = () => {
    const { workflow: { id } } = this.props;
    this.props.dispatch({
      type: 'workflows/delete',
      payload: {
        id,
      },
    });
  }

  render() {
    const { submitting, type } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmitWorkflow} layout="vertical">
        <FormError />
        <FormItem label="Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please enter a name',
              },
            ],
          })(<Input placeholder="eg. Patient Onboarding" />)}
        </FormItem>
        <FormItem className="drawer-footer">
          <Button onClick={this.handleSubmitWorkflow} type="primary" loading={submitting}>
            Save
          </Button>
          <Button className={styles.buttonStyles} onClick={this.props.onCancel}>
            Cancel
          </Button>
          {type === 'edit' && <Button onClick={showConfirm(this.handleDelete)} type="danger" className={styles.deleteButton}>
            Delete
          </Button>}
        </FormItem>
      </Form>
    );
  }
}
