import React, { PureComponent } from 'react';
import { Slider as AntdSlider } from 'antd';

class Slider extends PureComponent {
  render() {
    return (
      <AntdSlider
        {...this.props}
        min={0}
        max={10}
        step={1}
        marks={{ 0: 0, 5: 5, 10: 10 }}
      />
    );
  }
}

export default Slider;
