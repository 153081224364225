import { connect } from 'dva';
import { Form } from 'antd';
import { compose, mapProps } from 'recompose';
import FormTrigger from './form_trigger';

export default compose(
  connect(({ workflows, loading, errors }) => ({
    workflow: workflows.resource,
    workflowOptions: workflows.options,
    submitting: loading.effects['workflows/createTrigger'],
    formErrors: errors.formErrors,
  })),
  Form.create(),
  mapProps(ownerProps => ({
    ...ownerProps,
    type: 'new',
  })),
)(FormTrigger);
