import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import inflection from 'inflection';
import _ from 'lodash';

import styles from './TemplatePreview.module.less';

class TemplatePreview extends Component {
  static propTypes = {
    template: PropTypes.shape({
      care_plan_activities: PropTypes.arrayOf(PropTypes.shape({
        plannable_type: PropTypes.string.isRequired,
        plannable_id: PropTypes.string.isRequired,
        title: PropTypes.string,
      })),
    }).isRequired,
  }

  renderActivities = () => {
    const { template: { care_plan_activities } } = this.props;
    const {
      content: contents,
      questionnaire: forms,
    } = _.groupBy(care_plan_activities, obj => _.lowerCase(obj.plannable_type));

    // TODO: Refactor
    return (
      <div>
        <h4>
          {forms && `${forms.length && forms.length} ${inflection.inflect('Questionnaire', forms.length)}`}
        </h4>
        <ul>{forms && forms.map(form => <li key={`questionnaire_${form.plannable_id}`}>{form.title}</li>)}</ul>
        <h4>
          {contents && `${contents.length && contents.length} ${inflection.inflect('Content Item', contents.length)}`}
        </h4>
        <ul>{contents && contents.map(content => <li key={`content_${content.plannable_id}`}>{content.title}</li>)}</ul>
      </div>
    );
  }

  render() {
    const { template } = this.props;
    return (
      <Card
        className={styles.templatePreview}
        title={template.title}
      >
        {this.renderActivities()}
      </Card>
    );
  }
}

export default TemplatePreview;
